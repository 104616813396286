* > .intro-x:nth-child(1) {
  z-index: 49;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(1) {
  z-index: 49;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(1) {
  z-index: 49;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(1) {
  z-index: 49;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(2) {
  z-index: 48;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(2) {
  z-index: 48;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(2) {
  z-index: 48;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(2) {
  z-index: 48;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(3) {
  z-index: 47;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(3) {
  z-index: 47;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(3) {
  z-index: 47;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(3) {
  z-index: 47;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(4) {
  z-index: 46;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(4) {
  z-index: 46;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(4) {
  z-index: 46;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(4) {
  z-index: 46;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(5) {
  z-index: 45;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(5) {
  z-index: 45;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(5) {
  z-index: 45;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(5) {
  z-index: 45;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(6) {
  z-index: 44;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(6) {
  z-index: 44;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(6) {
  z-index: 44;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(6) {
  z-index: 44;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(7) {
  z-index: 43;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(7) {
  z-index: 43;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(7) {
  z-index: 43;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(7) {
  z-index: 43;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(8) {
  z-index: 42;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(8) {
  z-index: 42;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(8) {
  z-index: 42;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(8) {
  z-index: 42;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(9) {
  z-index: 41;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(9) {
  z-index: 41;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(9) {
  z-index: 41;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(9) {
  z-index: 41;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 0.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(10) {
  z-index: 40;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(10) {
  z-index: 40;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(10) {
  z-index: 40;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(10) {
  z-index: 40;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(11) {
  z-index: 39;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(11) {
  z-index: 39;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(11) {
  z-index: 39;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(11) {
  z-index: 39;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(12) {
  z-index: 38;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(12) {
  z-index: 38;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(12) {
  z-index: 38;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(12) {
  z-index: 38;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(13) {
  z-index: 37;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(13) {
  z-index: 37;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(13) {
  z-index: 37;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(13) {
  z-index: 37;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(14) {
  z-index: 36;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(14) {
  z-index: 36;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(14) {
  z-index: 36;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(14) {
  z-index: 36;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(15) {
  z-index: 35;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(15) {
  z-index: 35;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(15) {
  z-index: 35;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(15) {
  z-index: 35;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(16) {
  z-index: 34;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(16) {
  z-index: 34;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(16) {
  z-index: 34;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(16) {
  z-index: 34;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(17) {
  z-index: 33;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(17) {
  z-index: 33;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(17) {
  z-index: 33;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(17) {
  z-index: 33;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(18) {
  z-index: 32;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(18) {
  z-index: 32;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(18) {
  z-index: 32;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(18) {
  z-index: 32;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(19) {
  z-index: 31;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(19) {
  z-index: 31;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(19) {
  z-index: 31;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(19) {
  z-index: 31;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 1.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(20) {
  z-index: 30;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(20) {
  z-index: 30;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(20) {
  z-index: 30;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(20) {
  z-index: 30;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(21) {
  z-index: 29;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(21) {
  z-index: 29;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(21) {
  z-index: 29;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(21) {
  z-index: 29;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(22) {
  z-index: 28;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(22) {
  z-index: 28;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(22) {
  z-index: 28;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(22) {
  z-index: 28;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(23) {
  z-index: 27;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(23) {
  z-index: 27;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(23) {
  z-index: 27;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(23) {
  z-index: 27;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(24) {
  z-index: 26;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(24) {
  z-index: 26;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(24) {
  z-index: 26;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(24) {
  z-index: 26;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(25) {
  z-index: 25;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(25) {
  z-index: 25;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(25) {
  z-index: 25;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(25) {
  z-index: 25;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(26) {
  z-index: 24;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(26) {
  z-index: 24;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(26) {
  z-index: 24;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(26) {
  z-index: 24;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(27) {
  z-index: 23;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(27) {
  z-index: 23;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(27) {
  z-index: 23;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(27) {
  z-index: 23;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(28) {
  z-index: 22;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(28) {
  z-index: 22;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(28) {
  z-index: 22;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(28) {
  z-index: 22;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(29) {
  z-index: 21;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(29) {
  z-index: 21;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(29) {
  z-index: 21;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(29) {
  z-index: 21;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(30) {
  z-index: 20;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(30) {
  z-index: 20;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(30) {
  z-index: 20;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(30) {
  z-index: 20;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(31) {
  z-index: 19;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(31) {
  z-index: 19;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(31) {
  z-index: 19;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(31) {
  z-index: 19;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(32) {
  z-index: 18;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(32) {
  z-index: 18;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(32) {
  z-index: 18;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(32) {
  z-index: 18;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(33) {
  z-index: 17;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(33) {
  z-index: 17;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(33) {
  z-index: 17;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(33) {
  z-index: 17;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(34) {
  z-index: 16;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(34) {
  z-index: 16;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(34) {
  z-index: 16;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(34) {
  z-index: 16;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(35) {
  z-index: 15;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(35) {
  z-index: 15;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(35) {
  z-index: 15;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(35) {
  z-index: 15;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(36) {
  z-index: 14;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(36) {
  z-index: 14;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(36) {
  z-index: 14;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(36) {
  z-index: 14;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(37) {
  z-index: 13;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(37) {
  z-index: 13;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(37) {
  z-index: 13;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(37) {
  z-index: 13;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(38) {
  z-index: 12;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(38) {
  z-index: 12;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(38) {
  z-index: 12;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(38) {
  z-index: 12;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(39) {
  z-index: 11;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(39) {
  z-index: 11;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(39) {
  z-index: 11;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(39) {
  z-index: 11;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 3.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(40) {
  z-index: 10;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(40) {
  z-index: 10;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(40) {
  z-index: 10;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(40) {
  z-index: 10;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(41) {
  z-index: 9;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(41) {
  z-index: 9;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.1s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(41) {
  z-index: 9;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(41) {
  z-index: 9;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.1s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(42) {
  z-index: 8;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(42) {
  z-index: 8;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.2s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(42) {
  z-index: 8;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(42) {
  z-index: 8;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.2s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(43) {
  z-index: 7;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(43) {
  z-index: 7;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.3s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(43) {
  z-index: 7;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(43) {
  z-index: 7;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.3s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(44) {
  z-index: 6;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(44) {
  z-index: 6;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.4s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(44) {
  z-index: 6;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(44) {
  z-index: 6;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.4s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(45) {
  z-index: 5;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(45) {
  z-index: 5;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(45) {
  z-index: 5;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(45) {
  z-index: 5;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(46) {
  z-index: 4;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(46) {
  z-index: 4;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.6s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(46) {
  z-index: 4;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(46) {
  z-index: 4;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.6s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(47) {
  z-index: 3;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(47) {
  z-index: 3;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.7s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(47) {
  z-index: 3;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(47) {
  z-index: 3;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.7s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(48) {
  z-index: 2;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(48) {
  z-index: 2;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.8s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(48) {
  z-index: 2;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(48) {
  z-index: 2;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.8s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(49) {
  z-index: 1;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(49) {
  z-index: 1;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.9s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(49) {
  z-index: 1;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(49) {
  z-index: 1;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 4.9s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x:nth-child(50) {
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .-intro-x:nth-child(50) {
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-x-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 5s; }

@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }

* > .intro-y:nth-child(50) {
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .-intro-y:nth-child(50) {
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 5s; }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }

* > .intro-x img {
  width: 1.5rem; }

.side-nav {
  width: 230px;
  overflow-x: hidden;
  padding-right: 1.25rem;
  padding-bottom: 4rem; }
  @media (max-width: 1279px) {
    .side-nav {
      width: 85px; } }
  @media (max-width: 767px) {
    .side-nav {
      width: 100%;
      padding-right: 0;
      padding-bottom: 0;
      overflow-x: inherit; } }
  .side-nav.side-nav--simple {
    width: 85px; }
    .side-nav.side-nav--simple .side-menu .side-menu__title {
      display: none; }
      .side-nav.side-nav--simple .side-menu .side-menu__title .side-menu__sub-icon {
        display: none; }
    .side-nav.side-nav--simple .side-menu:not(.side-menu--active) .side-menu__icon:before {
      display: none; }
  .side-nav .side-nav__devider {
    opacity: 1;
    background-color: #6faff0;
    height: 1px;
    position: relative;
    width: 100%;
    z-index: 10; }
  .side-nav .side-menu {
    height: 50px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    padding-left: 1.25rem;
    position: relative;
    opacity: 1;
    color: #FFF; }
    @media (max-width: 767px) {
      .side-nav .side-menu {
        padding-left: 0.75rem;
        padding-right: 0.75rem; } }
    .side-nav .side-menu .side-menu__title {
      display: flex;
      align-items: center;
      margin-left: 0.75rem;
      width: 100%; }
      .side-nav .side-menu .side-menu__title .side-menu__sub-icon {
        height: 1rem;
        margin-right: 1.25rem;
        margin-left: auto;
        width: 1rem;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transition-duration: 100ms; }
        @media (min-width: 768px) and (max-width: 1279px) {
          .side-nav .side-menu .side-menu__title .side-menu__sub-icon {
            display: none; } }
        @media (max-width: 767px) {
          .side-nav .side-menu .side-menu__title .side-menu__sub-icon {
            margin-right: 0; } }
      @media (min-width: 768px) and (max-width: 1279px) {
        .side-nav .side-menu .side-menu__title {
          display: none; } }
  .side-nav > ul > li:nth-child(1).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(1) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(1) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(2).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(2) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(2) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(3).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(3) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(3) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(4).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(4) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(4) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(5).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(5) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(5) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(6).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.6s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(6) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.6s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(6) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(7).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.7s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(7) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.7s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(7) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(8).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.8s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(8) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.8s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(8) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(9).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.9s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(9) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 0.9s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(9) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(10).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(10) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(10) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(11).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(11) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(11) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(12).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(12) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(12) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(13).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(13) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(13) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(14).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(14) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(14) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(15).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(15) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(15) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(16).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.6s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(16) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.6s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(16) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(17).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.7s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(17) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.7s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(17) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(18).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.8s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(18) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.8s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(18) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(19).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.9s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(19) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 1.9s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(19) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(20).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(20) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(20) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(21).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(21) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(21) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(22).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(22) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(22) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(23).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(23) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(23) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(24).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(24) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(24) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(25).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(25) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(25) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(26).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.6s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(26) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.6s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(26) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(27).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.7s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(27) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.7s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(27) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(28).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.8s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(28) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.8s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(28) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(29).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.9s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(29) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 2.9s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(29) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(30).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(30) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(30) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(31).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(31) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(31) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(32).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(32) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(32) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(33).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(33) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(33) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(34).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(34) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(34) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(35).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(35) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(35) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(36).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.6s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(36) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.6s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(36) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(37).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.7s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(37) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.7s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(37) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(38).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.8s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(38) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.8s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(38) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(39).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.9s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(39) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 3.9s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(39) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(40).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(40) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(40) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(41).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.1s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(41) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.1s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(41) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(42).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.2s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(42) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.2s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(42) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(43).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.3s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(43) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.3s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(43) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(44).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.4s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(44) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.4s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(44) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(45).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(45) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(45) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(46).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.6s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(46) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.6s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(46) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(47).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.7s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(47) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.7s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(47) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(48).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.8s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(48) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.8s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(48) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(49).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.9s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(49) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 4.9s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(49) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  .side-nav > ul > li:nth-child(50).side-nav__devider {
    opacity: 0;
    -webkit-animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 5s; }

@keyframes intro-devider-animation {
  100% {
    opacity: 1; } }
  .side-nav > ul > li:nth-child(50) > a:not(.side-menu--active) {
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-delay: 5s; }

@keyframes intro-menu-animation {
  100% {
    opacity: 1;
    transform: translateX(0px); } }
  .side-nav > ul > li:nth-child(50) > a.side-menu--active {
    -webkit-animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

@keyframes intro-active-menu-animation {
  100% {
    z-index: 10; } }
  @media (max-width: 767px) {
    .side-nav > ul {
      margin-bottom: 30px; } }
  .side-nav > ul ul {
    opacity: 1;
    background-color: #1675d3;
    border-radius: 0.375rem;
    display: none;
    width: 100% !important;
    -webkit-animation: 0.4s intro-y-animation  ease-in-out 0.33333s;
    animation: 0.4s intro-y-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
    .side-nav > ul ul li:nth-child(1) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(2) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(3) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(4) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(5) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(6) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.6s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(7) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.7s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(8) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.8s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(9) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 0.9s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(10) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(11) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(12) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(13) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(14) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(15) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(16) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.6s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(17) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.7s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(18) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.8s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(19) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 1.9s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(20) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(21) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(22) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(23) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(24) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(25) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(26) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.6s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(27) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.7s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(28) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.8s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(29) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 2.9s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(30) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(31) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(32) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(33) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(34) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(35) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(36) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.6s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(37) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.7s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(38) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.8s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(39) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 3.9s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(40) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(41) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.1s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(42) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.2s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(43) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.3s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(44) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.4s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(45) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(46) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.6s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(47) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.7s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(48) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.8s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(49) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 4.9s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }
    .side-nav > ul ul li:nth-child(50) > a {
      opacity: 0;
      transform: translateX(50px);
      -webkit-animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      animation: 0.2s intro-submenu-animation ease-in-out .33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      animation-delay: 5s; }

@keyframes intro-submenu-animation {
  100% {
    opacity: 100;
    transform: translateX(0px); } }

@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px); } }
    .side-nav > ul ul.side-menu__sub-open {
      display: block; }
    .side-nav > ul ul .side-menu__icon:before {
      display: none; }
    .side-nav > ul ul li a:not(.side-menu--active) {
      opacity: 1;
      color: #e6e6e6; }
    .side-nav > ul ul li a.side-menu--active .side-menu__title {
      font-weight: 500; }
    .side-nav > ul ul ul {
      opacity: 1;
      background-color: #142c91;
      border-radius: 0.375rem;
      display: none; }
  .side-nav > ul > li > .side-menu .menu-curve {
    display: none; }
  .side-nav > ul > li > .side-menu .side-menu__icon {
    font-size: 22px; }
  .side-nav > ul > li > .side-menu.side-menu--active {
    opacity: 1;
    background-color: #f1f5f8; }
    @media (max-width: 767px) {
      .side-nav > ul > li > .side-menu.side-menu--active {
        background-color: transparent; } }
    .side-nav > ul > li > .side-menu.side-menu--active .menu-curve {
      display: block;
      width: 30px;
      height: 30px;
      margin-top: 50px;
      transform: scale(1.04);
      background-size: 100%;
      margin-right: -1.25rem;
      position: absolute;
      top: 0px;
      right: 0px; }
      .side-nav > ul > li > .side-menu.side-menu--active .menu-curve.rotate-curve {
        margin-top: -30px;
        transform: rotate(90deg) scale(1.04); }
      .side-nav > ul > li > .side-menu.side-menu--active .menu-curve path {
        fill: #f1f5f8;
        transform: scale(0.115); }
      @media (max-width: 767px) {
        .side-nav > ul > li > .side-menu.side-menu--active .menu-curve {
          display: none; } }
    .side-nav > ul > li > .side-menu.side-menu--active .side-menu__icon {
      color: #4a9bec; }
      @media (max-width: 767px) {
        .side-nav > ul > li > .side-menu.side-menu--active .side-menu__icon {
          color: inherit; } }
      .side-nav > ul > li > .side-menu.side-menu--active .side-menu__icon:before {
        content: "";
        z-index: -1;
        opacity: 1;
        background-color: #f1f5f8;
        height: 100%;
        margin-right: -1.25rem;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 3rem; }
        @media (max-width: 767px) {
          .side-nav > ul > li > .side-menu.side-menu--active .side-menu__icon:before {
            display: none; } }
    .side-nav > ul > li > .side-menu.side-menu--active .side-menu__title {
      font-weight: 500;
      color: #1f2937; }
      @media (max-width: 767px) {
        .side-nav > ul > li > .side-menu.side-menu--active .side-menu__title {
          color: inherit; } }
  .side-nav > ul > li > .side-menu:not(.side-menu--active) .side-menu__icon:before {
    content: "";
    z-index: -1;
    width: 230px;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 100ms; }
    @media (max-width: 1279px) {
      .side-nav > ul > li > .side-menu:not(.side-menu--active) .side-menu__icon:before {
        display: none; } }
  .side-nav > ul > li > .side-menu:hover:not(.side-menu--active):not(.side-menu--open) .side-menu__icon:before {
    opacity: 1;
    background-color: #1675d3; }

.mobile-menu {
  opacity: 1;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 0rem;
  margin-top: -1.25rem; }
  @media (max-width: 575px) {
    .mobile-menu {
      margin-left: -0.75rem;
      margin-right: -0.75rem; } }
  .mobile-menu .mobile-menu-bar {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (max-width: 575px) {
      .mobile-menu .mobile-menu-bar {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        height: 50px; } }
    .mobile-menu .mobile-menu-bar img {
      width: 1.5rem; }
    .mobile-menu .mobile-menu-bar #mobile-menu-toggler i {
      font-size: 26px; }
  .mobile-menu ul.menu__sub-open {
    display: block; }
  .mobile-menu ul li {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    .mobile-menu ul li ul {
      opacity: 1;
      background-color: #1675d3;
      border-radius: 0.375rem;
      display: none;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem; }
      @media (max-width: 575px) {
        .mobile-menu ul li ul {
          margin-left: 0.75rem;
          margin-right: 0.75rem; } }
      .mobile-menu ul li ul .menu {
        padding-left: 1rem;
        padding-right: 1rem; }
      .mobile-menu ul li ul ul {
        opacity: 1;
        background-color: #142c91;
        border-radius: 0.375rem;
        display: none;
        margin-left: 0px;
        margin-right: 0px; }
  .mobile-menu .menu__devider {
    opacity: 1;
    background-color: #6faff0;
    height: 1px;
    position: relative;
    width: 100%; }
  .mobile-menu .menu {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    opacity: 1;
    color: #FFF; }
    @media (max-width: 575px) {
      .mobile-menu .menu {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    .mobile-menu .menu .menu__icon {
      font-size: 20px; }
    .mobile-menu .menu .menu__title {
      display: flex;
      align-items: center;
      margin-left: 0.75rem;
      width: 100%; }
      .mobile-menu .menu .menu__title .menu__sub-icon {
        height: 1.25rem;
        margin-left: auto;
        width: 1.25rem;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transition-duration: 100ms; }

.content {
  border-radius: 30px;
  padding: 0px 22px;
  opacity: 1;
  background-color: #f1f5f8;
  flex: 1 1 0%;
  min-height: calc(100vh - 40px);
  min-width: 0px;
  padding-bottom: 2.5rem; }
  @media (max-width: 767px) {
    .content {
      max-width: 100%;
      padding-left: .75rem;
      padding-right: .75rem; } }
  .content:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%; }

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  padding: 0.6rem 0.75rem;
  border: 1px solid #dee2e6; }
  .input.form-control {
    font-size: inherit;
    line-height: inherit; }
  .input:disabled {
    cursor: not-allowed; }
  .input:not(textarea).input--sm {
    padding: 0.4rem 0.5rem; }
  .input:not(textarea).input--lg {
    padding: 0.85rem 1rem; }
  .input:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: none;
    border-color: inherit; }
  .input[type="radio"] {
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    cursor: pointer;
    overflow: hidden;
    position: relative; }
    .input[type="radio"]:before {
      content: "";
      width: 10px;
      height: 10px;
      transition: all 0.2s ease-in-out;
      background-color: #4a9bec;
      border-radius: 9999px;
      margin: auto;
      opacity: 0;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px; }
    .input[type="radio"]:checked {
      background-color: #4a9bec; }
      .input[type="radio"]:checked:before {
        opacity: 1; }
  .input[type="checkbox"]:not(.input--switch) {
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 0.2em;
    cursor: pointer;
    overflow: hidden;
    position: relative; }
    .input[type="checkbox"]:not(.input--switch):before {
      content: "";
      transition: all 0.2s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      opacity: 0;
      position: absolute;
      color: #FFF;
      width: 100%; }
    .input[type="checkbox"]:not(.input--switch):checked {
      background-color: #4a9bec;
      border-color: #4a9bec; }
      .input[type="checkbox"]:not(.input--switch):checked:before {
        opacity: 1; }
  .input.input--switch[type="checkbox"] {
    width: 38px;
    height: 24px;
    padding: 1px;
    border-radius: 9999px;
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative; }
    .input.input--switch[type="checkbox"]:before {
      content: "";
      width: 22px;
      height: 22px;
      transition: all 0.2s ease-in-out;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 9999px;
      margin-top: auto;
      margin-bottom: auto;
      position: absolute;
      top: 0px;
      bottom: 0px; }
    .input.input--switch[type="checkbox"]:checked {
      background-color: #4a9bec;
      border-color: #4a9bec; }
      .input.input--switch[type="checkbox"]:checked::before {
        margin-left: 13px;
        background-color: #FFF; }

.input-group-info {
  color: #4b5563;
  background-color: #f3f4f6; }

select.input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74, 85, 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-size: 15px;
  background-position: center right 0.60rem;
  background-color: #FFF;
  background-repeat: no-repeat;
  padding-right: 2rem; }
  select.input.input--sm {
    padding-right: 2rem; }
  select.input.input--lg {
    padding-right: 2rem; }
  select.input.form-select {
    font-size: inherit;
    line-height: inherit; }

.has-error .input {
  border-color: #d32929 !important; }

.button {
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px; }
  .button:not(button) {
    text-align: center; }
  .button.button--sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .button.button--lg {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem; }

.box {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.04);
  opacity: 1;
  background-color: white;
  border-radius: 0.375rem;
  position: relative;
  border: none !important; }

.top-bar {
  height: 67px;
  z-index: 51;
  opacity: 1;
  border-color: #f9fcfe;
  border-bottom-width: 1px;
  display: flex;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 767px) {
    .top-bar {
      height: 50px; } }

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  .breadcrumb.breadcrumb--light {
    opacity: 1;
    border-color: #2e51bb;
    border-left-width: 1px;
    height: 100%;
    margin-left: 2.5rem;
    padding-left: 2.5rem;
    opacity: 1;
    color: #c6d4fd; }
    @media (max-width: 767px) {
      .breadcrumb.breadcrumb--light {
        border-left-width: 0px;
        margin-left: 0px;
        padding-left: 0px; } }
    .breadcrumb.breadcrumb--light .breadcrumb--active {
      opacity: 1;
      color: #e8eeff; }
  .breadcrumb .breadcrumb__icon {
    width: 14px;
    height: 14px;
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .breadcrumb .breadcrumb--active {
    font-weight: 500;
    opacity: 1;
    color: #4a9bec; }

.search {
  position: relative; }
  .search .search__input {
    background-color: white;
    border-radius: 9999px;
    padding-right: 2rem;
    width: 14rem; }
  .search .search__icon {
    height: 1.25rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.75rem;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    color: #4a9bec;
    width: 1.25rem;
    font-size: 18px; }

.search-result {
  width: 450px;
  margin-top: 0.25rem;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  z-index: 20;
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s; }
  .search-result.show {
    transition: visibility 0s linear 0s, opacity 0.2s 0s;
    opacity: 1;
    visibility: visible; }
    .search-result.show > .search-result__content {
      margin-top: -1px; }
      .search-result.show > .search-result__content .tab-content .tab-content__pane {
        visibility: visible; }
  .search-result .search-result__content {
    transition: margin-top 0.2s;
    box-shadow: 0px 3px 20px #000000;
    opacity: 1;
    background-color: white;
    border-radius: 0.375rem;
    position: relative; }
    .search-result .search-result__content .search-result__content__title {
      font-weight: 500;
      margin-bottom: 0.5rem; }

.notification-content {
  width: 350px; }
  @media (max-width: 575px) {
    .notification-content {
      width: 280px; } }
  .notification-content .notification-content__title {
    font-weight: 500;
    margin-bottom: 1.25rem; }
  .notification-content .notification-content__box {
    padding: 1.25rem; }

.notification {
  position: relative;
  opacity: 1;
  color: #365a74; }
  .notification.notification--light {
    opacity: 1;
    color: #98aff5; }
  .notification .notification__icon {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 18px; }
  .notification.notification--bullet:before {
    content: "";
    width: 8px;
    height: 8px;
    margin-top: -2px;
    opacity: 1;
    background-color: #d32929;
    border-radius: 9999px;
    position: absolute;
    right: 0px; }

.dropdown-box {
  z-index: 9999;
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden; }
  .dropdown-box.show {
    transition: visibility 0s linear 0s, opacity 0.2s 0s;
    opacity: 1;
    visibility: visible; }
    .dropdown-box.show > .dropdown-box__content {
      margin-top: 0.25rem; }
      .dropdown-box.show > .dropdown-box__content .tab-content .tab-content__pane {
        visibility: visible; }
  .dropdown-box .dropdown-box__content {
    transition: margin-top 0.2s;
    margin-top: 1.25rem; }
    .dropdown-box .dropdown-box__content .tab-content .tab-content__pane {
      visibility: hidden; }

.dropdown-menu {
  border: none;
  padding: 0;
  font-size: 14px;
  background: transparent; }

.image-fit {
  position: relative; }
  .image-fit > img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%; }

.report-box {
  position: relative; }
  .report-box:before {
    content: "";
    width: 85%;
    background: #F9FAFC;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.04);
    border-radius: 0.375rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.75rem;
    position: absolute;
    right: 0px;
    left: 0px; }
  .report-box .report-box__icon {
    font-size: 28px; }
  .report-box .report-box__indicator {
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-right: 0.25rem;
    padding-left: 0.5rem;
    opacity: 1;
    color: #FFF; }

.litepicker {
  opacity: 1;
  background-color: #FFF;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 7px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.14); }
  .litepicker:before {
    content: "";
    left: 9px;
    top: -7px;
    border-bottom-color: #f7f7f7;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #e8e8e8;
    display: inline-block;
    position: absolute; }
  .litepicker:after {
    content: "";
    left: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    display: inline-block;
    position: absolute; }
  .litepicker .container__months,
  .litepicker .container__footer {
    background-color: transparent;
    padding-top: 0px;
    box-shadow: none; }
  .litepicker .container__footer {
    opacity: 1;
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin: 0px;
    padding: 0.75rem; }
    .litepicker .container__footer .button-apply,
    .litepicker .container__footer .button-cancel {
      border-radius: 0.375rem;
      font-weight: 500;
      margin-right: 0px;
      margin-left: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      width: 5rem; }
      .litepicker .container__footer .button-apply:focus,
      .litepicker .container__footer .button-cancel:focus {
        outline: 2px solid transparent;
        outline-offset: 2px; }
    .litepicker .container__footer .button-apply {
      opacity: 1;
      background-color: #4a9bec;
      opacity: 1;
      color: white; }
    .litepicker .container__footer .button-cancel {
      opacity: 1;
      background-color: #f1f5f8;
      color: #1f2937; }
  .litepicker .container__months {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
    .litepicker .container__months .month-item-weekdays-row {
      margin-top: 0.75rem;
      color: #6b7280; }
    .litepicker .container__months .month-item-header {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-top: 0.5rem; }
      .litepicker .container__months .month-item-header .button-previous-month {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem; }
        .litepicker .container__months .month-item-header .button-previous-month:hover {
          background-color: #e5e7eb; }
        .litepicker .container__months .month-item-header .button-previous-month svg {
          transform: rotate(135deg);
          margin-right: -0.25rem; }
      .litepicker .container__months .month-item-header .button-next-month {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem; }
        .litepicker .container__months .month-item-header .button-next-month:hover {
          background-color: #e5e7eb; }
        .litepicker .container__months .month-item-header .button-next-month svg {
          transform: rotate(-45deg);
          margin-left: -0.25rem; }
      .litepicker .container__months .month-item-header .button-previous-month:hover svg,
      .litepicker .container__months .month-item-header .button-previous-month svg,
      .litepicker .container__months .month-item-header .button-next-month:hover svg,
      .litepicker .container__months .month-item-header .button-next-month svg {
        fill: transparent;
        border: solid black;
        border-width: 0 2px 2px 0;
        border-radius: 0;
        display: inline-block;
        border-color: #4a5568;
        display: block;
        height: 0.5rem;
        width: 0.5rem; }
      .litepicker .container__months .month-item-header div > .month-item-name,
      .litepicker .container__months .month-item-header div > .month-item-year {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74, 85, 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
        background-size: 15px;
        background-position: center right 0.60rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        background-repeat: no-repeat;
        border-radius: 0.375rem;
        border-width: 1px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 2rem; }
  .litepicker .container__days .day-item {
    color: #1f2937; }
    .litepicker .container__days .day-item:hover {
      background-color: #4a9bec;
      box-shadow: none;
      color: #FFF; }
    .litepicker .container__days .day-item.is-today, .litepicker .container__days .day-item.is-today:hover {
      color: #d32929; }
    .litepicker .container__days .day-item.is-start-date, .litepicker .container__days .day-item.is-start-date:hover, .litepicker .container__days .day-item.is-end-date, .litepicker .container__days .day-item.is-end-date:hover {
      background-color: #4a9bec;
      color: #FFF; }
    .litepicker .container__days .day-item.is-in-range, .litepicker .container__days .day-item.is-in-range:hover {
      background-color: #ecf4fd; }
  .litepicker .container__days .week-number {
    color: #6b7280; }

body {
  font-family: "Roboto", sans-serif;
  background-color: #4a9bec;
  font-size: 14px;
  overflow-x: hidden;
  line-height: 1.3;
  color: #1f2937;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (max-width: 575px) {
    body {
      padding-left: 0.75rem;
      padding-right: 0.75rem; } }

*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

a, a:hover {
  text-decoration: none;
  color: inherit; }

img {
  max-width: 100%; }

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem; }

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem; }

.text-base {
  font-size: 1rem;
  line-height: 1.5rem; }

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem; }

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem; }

.font-medium {
  font-weight: 500; }

.font-bold {
  font-weight: 700; }

.font-normal {
  font-weight: 400; }

.f-20 {
  font-size: 20px; }

.f-40 {
  font-size: 40px; }

.f-60 {
  font-size: 60px; }

.transform {
  transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1); }

.rotate-180 {
  transform: rotate(180deg); }

.-rotate-90 {
  transform: rotate(-90deg); }

.cursor-pointer {
  cursor: pointer; }

.dropdown-toggle::after {
  display: none; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.flex-none {
  flex: none; }

.flex-1 {
  flex: 1 1 0%; }

.text-gray-600 {
  color: #4b5563; }

.text-gray-500 {
  color: #616e80; }

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem; }

.text-base {
  font-size: 1rem;
  line-height: 1.5rem; }

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem; }

.theme-bg {
  background-color: #4a9bec; }

.text-theme-color {
  color: #4a9bec; }

.border-theme-color {
  border-color: #f9fcfe; }

.hover-theme-bg:hover {
  background-color: #78b5f1; }

.hover-gray-bg:hover {
  background-color: #dfe3e7 !important; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.col-blue {
  color: #3160d8; }

.col-orange {
  color: #f78b00; }

.col-yellow {
  color: #fbc500; }

.col-green {
  color: #91c714; }

.col-red {
  color: #d32929; }

.bg-blue {
  background-color: #3160d8; }

.bg-orange {
  background-color: #f78b00; }

.bg-yellow {
  background-color: #fbc500; }

.bg-green {
  background-color: #91c714; }

.bg-red {
  background-color: #d32929; }

.bg-gray {
  background-color: #dfe3e7; }

.bg-transparent {
  background-color: transparent; }

@media (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: transparent !important; } }

.border-yellow {
  border-color: #fbc500 !important; }

.border-green {
  border-color: #91c714 !important; }

.border-red {
  border-color: #d32929 !important; }

.whitespace-no-wrap {
  white-space: nowrap; }

@media (min-width: 1024px) {
  .overflow-lg-visible {
    overflow: visible !important; } }

@media (min-width: 576px) {
  .sm\:border-t {
    border-top-width: 1px; } }

@media (min-width: 768px) {
  .md\:border-t {
    border-top-width: 1px; } }

@media (min-width: 992px) {
  .lg\:border-t {
    border-top-width: 1px; } }

@media (min-width: 1200px) {
  .xl\:border-t {
    border-top-width: 1px; } }

@media (min-width: 1400px) {
  .xxl\:border-t {
    border-top-width: 1px; } }

.border-t {
  border-top-width: 1px; }

@media (min-width: 576px) {
  .sm\:border-l {
    border-left-width: 1px; } }

@media (min-width: 768px) {
  .md\:border-l {
    border-left-width: 1px; } }

@media (min-width: 992px) {
  .lg\:border-l {
    border-left-width: 1px; } }

@media (min-width: 1200px) {
  .xl\:border-l {
    border-left-width: 1px; } }

@media (min-width: 1400px) {
  .xxl\:border-l {
    border-left-width: 1px; } }

.border-l {
  border-left-width: 1px; }

@media (min-width: 576px) {
  .sm\:border-b {
    border-bottom-width: 1px; } }

@media (min-width: 768px) {
  .md\:border-b {
    border-bottom-width: 1px; } }

@media (min-width: 992px) {
  .lg\:border-b {
    border-bottom-width: 1px; } }

@media (min-width: 1200px) {
  .xl\:border-b {
    border-bottom-width: 1px; } }

@media (min-width: 1400px) {
  .xxl\:border-b {
    border-bottom-width: 1px; } }

.border-b {
  border-bottom-width: 1px; }

@media (min-width: 576px) {
  .sm\:border-r {
    border-right-width: 1px; } }

@media (min-width: 768px) {
  .md\:border-r {
    border-right-width: 1px; } }

@media (min-width: 992px) {
  .lg\:border-r {
    border-right-width: 1px; } }

@media (min-width: 1200px) {
  .xl\:border-r {
    border-right-width: 1px; } }

@media (min-width: 1400px) {
  .xxl\:border-r {
    border-right-width: 1px; } }

.border-r {
  border-right-width: 1px; }

.border-dashed {
  border-style: dashed; }

.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.z-6 {
  z-index: 6; }

.z-7 {
  z-index: 7; }

.z-8 {
  z-index: 8; }

.z-9 {
  z-index: 9; }

.z-10 {
  z-index: 10; }

.z-11 {
  z-index: 11; }

.z-12 {
  z-index: 12; }

.z-13 {
  z-index: 13; }

.z-14 {
  z-index: 14; }

.z-15 {
  z-index: 15; }

.z-16 {
  z-index: 16; }

.z-17 {
  z-index: 17; }

.z-18 {
  z-index: 18; }

.z-19 {
  z-index: 19; }

.z-20 {
  z-index: 20; }

.z-21 {
  z-index: 21; }

.z-22 {
  z-index: 22; }

.z-23 {
  z-index: 23; }

.z-24 {
  z-index: 24; }

.z-25 {
  z-index: 25; }

.z-26 {
  z-index: 26; }

.z-27 {
  z-index: 27; }

.z-28 {
  z-index: 28; }

.z-29 {
  z-index: 29; }

.z-30 {
  z-index: 30; }

.z-31 {
  z-index: 31; }

.z-32 {
  z-index: 32; }

.z-33 {
  z-index: 33; }

.z-34 {
  z-index: 34; }

.z-35 {
  z-index: 35; }

.z-36 {
  z-index: 36; }

.z-37 {
  z-index: 37; }

.z-38 {
  z-index: 38; }

.z-39 {
  z-index: 39; }

.z-40 {
  z-index: 40; }

.z-41 {
  z-index: 41; }

.z-42 {
  z-index: 42; }

.z-43 {
  z-index: 43; }

.z-44 {
  z-index: 44; }

.z-45 {
  z-index: 45; }

.z-46 {
  z-index: 46; }

.z-47 {
  z-index: 47; }

.z-48 {
  z-index: 48; }

.z-49 {
  z-index: 49; }

.z-50 {
  z-index: 50; }

.z-51 {
  z-index: 51; }

.z-52 {
  z-index: 52; }

.z-53 {
  z-index: 53; }

.z-54 {
  z-index: 54; }

.z-55 {
  z-index: 55; }

.z-56 {
  z-index: 56; }

.z-57 {
  z-index: 57; }

.z-58 {
  z-index: 58; }

.z-59 {
  z-index: 59; }

.z-60 {
  z-index: 60; }

.z-61 {
  z-index: 61; }

.z-62 {
  z-index: 62; }

.z-63 {
  z-index: 63; }

.z-64 {
  z-index: 64; }

.z-65 {
  z-index: 65; }

.z-66 {
  z-index: 66; }

.z-67 {
  z-index: 67; }

.z-68 {
  z-index: 68; }

.z-69 {
  z-index: 69; }

.z-70 {
  z-index: 70; }

.z-71 {
  z-index: 71; }

.z-72 {
  z-index: 72; }

.z-73 {
  z-index: 73; }

.z-74 {
  z-index: 74; }

.z-75 {
  z-index: 75; }

.z-76 {
  z-index: 76; }

.z-77 {
  z-index: 77; }

.z-78 {
  z-index: 78; }

.z-79 {
  z-index: 79; }

.z-80 {
  z-index: 80; }

.z-81 {
  z-index: 81; }

.z-82 {
  z-index: 82; }

.z-83 {
  z-index: 83; }

.z-84 {
  z-index: 84; }

.z-85 {
  z-index: 85; }

.z-86 {
  z-index: 86; }

.z-87 {
  z-index: 87; }

.z-88 {
  z-index: 88; }

.z-89 {
  z-index: 89; }

.z-90 {
  z-index: 90; }

.z-91 {
  z-index: 91; }

.z-92 {
  z-index: 92; }

.z-93 {
  z-index: 93; }

.z-94 {
  z-index: 94; }

.z-95 {
  z-index: 95; }

.z-96 {
  z-index: 96; }

.z-97 {
  z-index: 97; }

.z-98 {
  z-index: 98; }

.z-99 {
  z-index: 99; }

.z-100 {
  z-index: 100; }

.w-0 {
  width: 0rem; }

.w-1 {
  width: 0.25rem; }

.w-2 {
  width: 0.5rem; }

.w-3 {
  width: 0.75rem; }

.w-4 {
  width: 1rem; }

.w-5 {
  width: 1.25rem; }

.w-6 {
  width: 1.5rem; }

.w-7 {
  width: 1.75rem; }

.w-8 {
  width: 2rem; }

.w-9 {
  width: 2.25rem; }

.w-10 {
  width: 2.5rem; }

.w-11 {
  width: 2.75rem; }

.w-12 {
  width: 3rem; }

.w-13 {
  width: 3.25rem; }

.w-14 {
  width: 3.5rem; }

.w-15 {
  width: 3.75rem; }

.w-16 {
  width: 5rem; }

.w-20 {
  width: 6rem; }

.w-24 {
  width: 7rem; }

.w-28 {
  width: 8rem; }

.w-32 {
  width: 9rem; }

.w-36 {
  width: 10rem; }

.w-40 {
  width: 11rem; }

.w-44 {
  width: 12rem; }

.w-48 {
  width: 13rem; }

.w-52 {
  width: 14rem; }

.w-56 {
  width: 15rem; }

.w-60 {
  width: 16rem; }

.w-64 {
  width: 17rem; }

@media (min-width: 576px) {
  .sm\:w-16 {
    width: 5rem; }
  .sm\:w-20 {
    width: 6rem; }
  .sm\:w-24 {
    width: 7rem; }
  .sm\:w-28 {
    width: 8rem; }
  .sm\:w-32 {
    width: 9rem; }
  .sm\:w-36 {
    width: 10rem; }
  .sm\:w-40 {
    width: 11rem; }
  .sm\:w-44 {
    width: 12rem; }
  .sm\:w-48 {
    width: 13rem; }
  .sm\:w-52 {
    width: 14rem; }
  .sm\:w-56 {
    width: 15rem; }
  .sm\:w-60 {
    width: 16rem; }
  .sm\:w-64 {
    width: 17rem; } }

.h-0 {
  height: 0rem; }

.h-1 {
  height: 0.25rem; }

.h-2 {
  height: 0.5rem; }

.h-3 {
  height: 0.75rem; }

.h-4 {
  height: 1rem; }

.h-5 {
  height: 1.25rem; }

.h-6 {
  height: 1.5rem; }

.h-7 {
  height: 1.75rem; }

.h-8 {
  height: 2rem; }

.h-9 {
  height: 2.25rem; }

.h-10 {
  height: 2.5rem; }

.h-11 {
  height: 2.75rem; }

.h-12 {
  height: 3rem; }

.h-13 {
  height: 3.25rem; }

.h-14 {
  height: 3.5rem; }

.h-15 {
  height: 3.75rem; }

.h-16 {
  height: 5rem; }

.h-20 {
  height: 6rem; }

.h-24 {
  height: 7rem; }

.h-28 {
  height: 8rem; }

.h-32 {
  height: 9rem; }

.h-36 {
  height: 10rem; }

.h-40 {
  height: 11rem; }

.h-44 {
  height: 12rem; }

.h-48 {
  height: 13rem; }

.h-52 {
  height: 14rem; }

.h-56 {
  height: 15rem; }

.h-60 {
  height: 16rem; }

.h-64 {
  height: 17rem; }

.-mb-0 {
  margin-bottom: 0rem; }

.-mt-0 {
  margin-bottom: 0rem !important; }

.-mr-0 {
  margin-right: 0rem; }

.-ml-0 {
  margin-left: 0rem; }

.m-0 {
  margin: 0rem !important; }

.mb-0 {
  margin-bottom: 0rem; }

.mt-0 {
  margin-top: 0rem !important; }

.ml-0 {
  margin-left: 0rem; }

.mr-0 {
  margin-right: 0rem; }

.p-0 {
  padding: 0rem !important; }

.pb-0 {
  padding-bottom: 0rem; }

.pt-0 {
  padding-top: 0rem !important; }

.pl-0 {
  padding-left: 0rem; }

.pr-0 {
  padding-right: 0rem; }

.-mb-1 {
  margin-bottom: -0.25rem; }

.-mt-1 {
  margin-bottom: -0.25rem !important; }

.-mr-1 {
  margin-right: -0.25rem; }

.-ml-1 {
  margin-left: -0.25rem; }

.m-1 {
  margin: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem; }

.mt-1 {
  margin-top: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem; }

.mr-1 {
  margin-right: 0.25rem; }

.p-1 {
  padding: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem; }

.pr-1 {
  padding-right: 0.25rem; }

.-mb-2 {
  margin-bottom: -0.5rem; }

.-mt-2 {
  margin-bottom: -0.5rem !important; }

.-mr-2 {
  margin-right: -0.5rem; }

.-ml-2 {
  margin-left: -0.5rem; }

.m-2 {
  margin: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem; }

.mt-2 {
  margin-top: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem; }

.mr-2 {
  margin-right: 0.5rem; }

.p-2 {
  padding: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem; }

.pr-2 {
  padding-right: 0.5rem; }

.-mb-3 {
  margin-bottom: -0.75rem; }

.-mt-3 {
  margin-bottom: -0.75rem !important; }

.-mr-3 {
  margin-right: -0.75rem; }

.-ml-3 {
  margin-left: -0.75rem; }

.m-3 {
  margin: 0.75rem !important; }

.mb-3 {
  margin-bottom: 0.75rem; }

.mt-3 {
  margin-top: 0.75rem !important; }

.ml-3 {
  margin-left: 0.75rem; }

.mr-3 {
  margin-right: 0.75rem; }

.p-3 {
  padding: 0.75rem !important; }

.pb-3 {
  padding-bottom: 0.75rem; }

.pt-3 {
  padding-top: 0.75rem !important; }

.pl-3 {
  padding-left: 0.75rem; }

.pr-3 {
  padding-right: 0.75rem; }

.-mb-4 {
  margin-bottom: -1rem; }

.-mt-4 {
  margin-bottom: -1rem !important; }

.-mr-4 {
  margin-right: -1rem; }

.-ml-4 {
  margin-left: -1rem; }

.m-4 {
  margin: 1rem !important; }

.mb-4 {
  margin-bottom: 1rem; }

.mt-4 {
  margin-top: 1rem !important; }

.ml-4 {
  margin-left: 1rem; }

.mr-4 {
  margin-right: 1rem; }

.p-4 {
  padding: 1rem !important; }

.pb-4 {
  padding-bottom: 1rem; }

.pt-4 {
  padding-top: 1rem !important; }

.pl-4 {
  padding-left: 1rem; }

.pr-4 {
  padding-right: 1rem; }

.-mb-5 {
  margin-bottom: -1.25rem; }

.-mt-5 {
  margin-bottom: -1.25rem !important; }

.-mr-5 {
  margin-right: -1.25rem; }

.-ml-5 {
  margin-left: -1.25rem; }

.m-5 {
  margin: 1.25rem !important; }

.mb-5 {
  margin-bottom: 1.25rem; }

.mt-5 {
  margin-top: 1.25rem !important; }

.ml-5 {
  margin-left: 1.25rem; }

.mr-5 {
  margin-right: 1.25rem; }

.p-5 {
  padding: 1.25rem !important; }

.pb-5 {
  padding-bottom: 1.25rem; }

.pt-5 {
  padding-top: 1.25rem !important; }

.pl-5 {
  padding-left: 1.25rem; }

.pr-5 {
  padding-right: 1.25rem; }

.-mb-6 {
  margin-bottom: -1.5rem; }

.-mt-6 {
  margin-bottom: -1.5rem !important; }

.-mr-6 {
  margin-right: -1.5rem; }

.-ml-6 {
  margin-left: -1.5rem; }

.m-6 {
  margin: 1.5rem !important; }

.mb-6 {
  margin-bottom: 1.5rem; }

.mt-6 {
  margin-top: 1.5rem !important; }

.ml-6 {
  margin-left: 1.5rem; }

.mr-6 {
  margin-right: 1.5rem; }

.p-6 {
  padding: 1.5rem !important; }

.pb-6 {
  padding-bottom: 1.5rem; }

.pt-6 {
  padding-top: 1.5rem !important; }

.pl-6 {
  padding-left: 1.5rem; }

.pr-6 {
  padding-right: 1.5rem; }

.-mb-7 {
  margin-bottom: -1.75rem; }

.-mt-7 {
  margin-bottom: -1.75rem !important; }

.-mr-7 {
  margin-right: -1.75rem; }

.-ml-7 {
  margin-left: -1.75rem; }

.m-7 {
  margin: 1.75rem !important; }

.mb-7 {
  margin-bottom: 1.75rem; }

.mt-7 {
  margin-top: 1.75rem !important; }

.ml-7 {
  margin-left: 1.75rem; }

.mr-7 {
  margin-right: 1.75rem; }

.p-7 {
  padding: 1.75rem !important; }

.pb-7 {
  padding-bottom: 1.75rem; }

.pt-7 {
  padding-top: 1.75rem !important; }

.pl-7 {
  padding-left: 1.75rem; }

.pr-7 {
  padding-right: 1.75rem; }

.-mb-8 {
  margin-bottom: -2rem; }

.-mt-8 {
  margin-bottom: -2rem !important; }

.-mr-8 {
  margin-right: -2rem; }

.-ml-8 {
  margin-left: -2rem; }

.m-8 {
  margin: 2rem !important; }

.mb-8 {
  margin-bottom: 2rem; }

.mt-8 {
  margin-top: 2rem !important; }

.ml-8 {
  margin-left: 2rem; }

.mr-8 {
  margin-right: 2rem; }

.p-8 {
  padding: 2rem !important; }

.pb-8 {
  padding-bottom: 2rem; }

.pt-8 {
  padding-top: 2rem !important; }

.pl-8 {
  padding-left: 2rem; }

.pr-8 {
  padding-right: 2rem; }

.-mb-9 {
  margin-bottom: -2.25rem; }

.-mt-9 {
  margin-bottom: -2.25rem !important; }

.-mr-9 {
  margin-right: -2.25rem; }

.-ml-9 {
  margin-left: -2.25rem; }

.m-9 {
  margin: 2.25rem !important; }

.mb-9 {
  margin-bottom: 2.25rem; }

.mt-9 {
  margin-top: 2.25rem !important; }

.ml-9 {
  margin-left: 2.25rem; }

.mr-9 {
  margin-right: 2.25rem; }

.p-9 {
  padding: 2.25rem !important; }

.pb-9 {
  padding-bottom: 2.25rem; }

.pt-9 {
  padding-top: 2.25rem !important; }

.pl-9 {
  padding-left: 2.25rem; }

.pr-9 {
  padding-right: 2.25rem; }

.-mb-10 {
  margin-bottom: -2.5rem; }

.-mt-10 {
  margin-bottom: -2.5rem !important; }

.-mr-10 {
  margin-right: -2.5rem; }

.-ml-10 {
  margin-left: -2.5rem; }

.m-10 {
  margin: 2.5rem !important; }

.mb-10 {
  margin-bottom: 2.5rem; }

.mt-10 {
  margin-top: 2.5rem !important; }

.ml-10 {
  margin-left: 2.5rem; }

.mr-10 {
  margin-right: 2.5rem; }

.p-10 {
  padding: 2.5rem !important; }

.pb-10 {
  padding-bottom: 2.5rem; }

.pt-10 {
  padding-top: 2.5rem !important; }

.pl-10 {
  padding-left: 2.5rem; }

.pr-10 {
  padding-right: 2.5rem; }

.-mb-11 {
  margin-bottom: -2.75rem; }

.-mt-11 {
  margin-bottom: -2.75rem !important; }

.-mr-11 {
  margin-right: -2.75rem; }

.-ml-11 {
  margin-left: -2.75rem; }

.m-11 {
  margin: 2.75rem !important; }

.mb-11 {
  margin-bottom: 2.75rem; }

.mt-11 {
  margin-top: 2.75rem !important; }

.ml-11 {
  margin-left: 2.75rem; }

.mr-11 {
  margin-right: 2.75rem; }

.p-11 {
  padding: 2.75rem !important; }

.pb-11 {
  padding-bottom: 2.75rem; }

.pt-11 {
  padding-top: 2.75rem !important; }

.pl-11 {
  padding-left: 2.75rem; }

.pr-11 {
  padding-right: 2.75rem; }

.-mb-12 {
  margin-bottom: -3rem; }

.-mt-12 {
  margin-bottom: -3rem !important; }

.-mr-12 {
  margin-right: -3rem; }

.-ml-12 {
  margin-left: -3rem; }

.m-12 {
  margin: 3rem !important; }

.mb-12 {
  margin-bottom: 3rem; }

.mt-12 {
  margin-top: 3rem !important; }

.ml-12 {
  margin-left: 3rem; }

.mr-12 {
  margin-right: 3rem; }

.p-12 {
  padding: 3rem !important; }

.pb-12 {
  padding-bottom: 3rem; }

.pt-12 {
  padding-top: 3rem !important; }

.pl-12 {
  padding-left: 3rem; }

.pr-12 {
  padding-right: 3rem; }

.-mb-13 {
  margin-bottom: -3.25rem; }

.-mt-13 {
  margin-bottom: -3.25rem !important; }

.-mr-13 {
  margin-right: -3.25rem; }

.-ml-13 {
  margin-left: -3.25rem; }

.m-13 {
  margin: 3.25rem !important; }

.mb-13 {
  margin-bottom: 3.25rem; }

.mt-13 {
  margin-top: 3.25rem !important; }

.ml-13 {
  margin-left: 3.25rem; }

.mr-13 {
  margin-right: 3.25rem; }

.p-13 {
  padding: 3.25rem !important; }

.pb-13 {
  padding-bottom: 3.25rem; }

.pt-13 {
  padding-top: 3.25rem !important; }

.pl-13 {
  padding-left: 3.25rem; }

.pr-13 {
  padding-right: 3.25rem; }

.-mb-14 {
  margin-bottom: -3.5rem; }

.-mt-14 {
  margin-bottom: -3.5rem !important; }

.-mr-14 {
  margin-right: -3.5rem; }

.-ml-14 {
  margin-left: -3.5rem; }

.m-14 {
  margin: 3.5rem !important; }

.mb-14 {
  margin-bottom: 3.5rem; }

.mt-14 {
  margin-top: 3.5rem !important; }

.ml-14 {
  margin-left: 3.5rem; }

.mr-14 {
  margin-right: 3.5rem; }

.p-14 {
  padding: 3.5rem !important; }

.pb-14 {
  padding-bottom: 3.5rem; }

.pt-14 {
  padding-top: 3.5rem !important; }

.pl-14 {
  padding-left: 3.5rem; }

.pr-14 {
  padding-right: 3.5rem; }

.-mb-15 {
  margin-bottom: -3.75rem; }

.-mt-15 {
  margin-bottom: -3.75rem !important; }

.-mr-15 {
  margin-right: -3.75rem; }

.-ml-15 {
  margin-left: -3.75rem; }

.m-15 {
  margin: 3.75rem !important; }

.mb-15 {
  margin-bottom: 3.75rem; }

.mt-15 {
  margin-top: 3.75rem !important; }

.ml-15 {
  margin-left: 3.75rem; }

.mr-15 {
  margin-right: 3.75rem; }

.p-15 {
  padding: 3.75rem !important; }

.pb-15 {
  padding-bottom: 3.75rem; }

.pt-15 {
  padding-top: 3.75rem !important; }

.pl-15 {
  padding-left: 3.75rem; }

.pr-15 {
  padding-right: 3.75rem; }

@media (min-width: 576px) {
  .sm\:-mb-0 {
    margin-bottom: 0rem; }
  .sm\:-mt-0 {
    margin-bottom: 0rem; }
  .sm\:-mr-0 {
    margin-right: 0rem; }
  .sm\:-ml-0 {
    margin-left: 0rem; }
  .sm\:mb-0 {
    margin-bottom: 0rem; }
  .sm\:mt-0 {
    margin-top: 0rem !important; }
  .sm\:ml-0 {
    margin-left: 0rem; }
  .sm\:mr-0 {
    margin-right: 0rem; }
  .sm\:pb-0 {
    padding-bottom: 0rem; }
  .sm\:pt-0 {
    padding-top: 0rem !important; }
  .sm\:pl-0 {
    padding-left: 0rem; }
  .sm\:pr-0 {
    padding-right: 0rem; }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem; }
  .sm\:-mt-1 {
    margin-bottom: -0.25rem; }
  .sm\:-mr-1 {
    margin-right: -0.25rem; }
  .sm\:-ml-1 {
    margin-left: -0.25rem; }
  .sm\:mb-1 {
    margin-bottom: 0.25rem; }
  .sm\:mt-1 {
    margin-top: 0.25rem !important; }
  .sm\:ml-1 {
    margin-left: 0.25rem; }
  .sm\:mr-1 {
    margin-right: 0.25rem; }
  .sm\:pb-1 {
    padding-bottom: 0.25rem; }
  .sm\:pt-1 {
    padding-top: 0.25rem !important; }
  .sm\:pl-1 {
    padding-left: 0.25rem; }
  .sm\:pr-1 {
    padding-right: 0.25rem; }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem; }
  .sm\:-mt-2 {
    margin-bottom: -0.5rem; }
  .sm\:-mr-2 {
    margin-right: -0.5rem; }
  .sm\:-ml-2 {
    margin-left: -0.5rem; }
  .sm\:mb-2 {
    margin-bottom: 0.5rem; }
  .sm\:mt-2 {
    margin-top: 0.5rem !important; }
  .sm\:ml-2 {
    margin-left: 0.5rem; }
  .sm\:mr-2 {
    margin-right: 0.5rem; }
  .sm\:pb-2 {
    padding-bottom: 0.5rem; }
  .sm\:pt-2 {
    padding-top: 0.5rem !important; }
  .sm\:pl-2 {
    padding-left: 0.5rem; }
  .sm\:pr-2 {
    padding-right: 0.5rem; }
  .sm\:-mb-3 {
    margin-bottom: -0.75rem; }
  .sm\:-mt-3 {
    margin-bottom: -0.75rem; }
  .sm\:-mr-3 {
    margin-right: -0.75rem; }
  .sm\:-ml-3 {
    margin-left: -0.75rem; }
  .sm\:mb-3 {
    margin-bottom: 0.75rem; }
  .sm\:mt-3 {
    margin-top: 0.75rem !important; }
  .sm\:ml-3 {
    margin-left: 0.75rem; }
  .sm\:mr-3 {
    margin-right: 0.75rem; }
  .sm\:pb-3 {
    padding-bottom: 0.75rem; }
  .sm\:pt-3 {
    padding-top: 0.75rem !important; }
  .sm\:pl-3 {
    padding-left: 0.75rem; }
  .sm\:pr-3 {
    padding-right: 0.75rem; }
  .sm\:-mb-4 {
    margin-bottom: -1rem; }
  .sm\:-mt-4 {
    margin-bottom: -1rem; }
  .sm\:-mr-4 {
    margin-right: -1rem; }
  .sm\:-ml-4 {
    margin-left: -1rem; }
  .sm\:mb-4 {
    margin-bottom: 1rem; }
  .sm\:mt-4 {
    margin-top: 1rem !important; }
  .sm\:ml-4 {
    margin-left: 1rem; }
  .sm\:mr-4 {
    margin-right: 1rem; }
  .sm\:pb-4 {
    padding-bottom: 1rem; }
  .sm\:pt-4 {
    padding-top: 1rem !important; }
  .sm\:pl-4 {
    padding-left: 1rem; }
  .sm\:pr-4 {
    padding-right: 1rem; }
  .sm\:-mb-5 {
    margin-bottom: -1.25rem; }
  .sm\:-mt-5 {
    margin-bottom: -1.25rem; }
  .sm\:-mr-5 {
    margin-right: -1.25rem; }
  .sm\:-ml-5 {
    margin-left: -1.25rem; }
  .sm\:mb-5 {
    margin-bottom: 1.25rem; }
  .sm\:mt-5 {
    margin-top: 1.25rem !important; }
  .sm\:ml-5 {
    margin-left: 1.25rem; }
  .sm\:mr-5 {
    margin-right: 1.25rem; }
  .sm\:pb-5 {
    padding-bottom: 1.25rem; }
  .sm\:pt-5 {
    padding-top: 1.25rem !important; }
  .sm\:pl-5 {
    padding-left: 1.25rem; }
  .sm\:pr-5 {
    padding-right: 1.25rem; }
  .sm\:-mb-6 {
    margin-bottom: -1.5rem; }
  .sm\:-mt-6 {
    margin-bottom: -1.5rem; }
  .sm\:-mr-6 {
    margin-right: -1.5rem; }
  .sm\:-ml-6 {
    margin-left: -1.5rem; }
  .sm\:mb-6 {
    margin-bottom: 1.5rem; }
  .sm\:mt-6 {
    margin-top: 1.5rem !important; }
  .sm\:ml-6 {
    margin-left: 1.5rem; }
  .sm\:mr-6 {
    margin-right: 1.5rem; }
  .sm\:pb-6 {
    padding-bottom: 1.5rem; }
  .sm\:pt-6 {
    padding-top: 1.5rem !important; }
  .sm\:pl-6 {
    padding-left: 1.5rem; }
  .sm\:pr-6 {
    padding-right: 1.5rem; }
  .sm\:-mb-7 {
    margin-bottom: -1.75rem; }
  .sm\:-mt-7 {
    margin-bottom: -1.75rem; }
  .sm\:-mr-7 {
    margin-right: -1.75rem; }
  .sm\:-ml-7 {
    margin-left: -1.75rem; }
  .sm\:mb-7 {
    margin-bottom: 1.75rem; }
  .sm\:mt-7 {
    margin-top: 1.75rem !important; }
  .sm\:ml-7 {
    margin-left: 1.75rem; }
  .sm\:mr-7 {
    margin-right: 1.75rem; }
  .sm\:pb-7 {
    padding-bottom: 1.75rem; }
  .sm\:pt-7 {
    padding-top: 1.75rem !important; }
  .sm\:pl-7 {
    padding-left: 1.75rem; }
  .sm\:pr-7 {
    padding-right: 1.75rem; }
  .sm\:-mb-8 {
    margin-bottom: -2rem; }
  .sm\:-mt-8 {
    margin-bottom: -2rem; }
  .sm\:-mr-8 {
    margin-right: -2rem; }
  .sm\:-ml-8 {
    margin-left: -2rem; }
  .sm\:mb-8 {
    margin-bottom: 2rem; }
  .sm\:mt-8 {
    margin-top: 2rem !important; }
  .sm\:ml-8 {
    margin-left: 2rem; }
  .sm\:mr-8 {
    margin-right: 2rem; }
  .sm\:pb-8 {
    padding-bottom: 2rem; }
  .sm\:pt-8 {
    padding-top: 2rem !important; }
  .sm\:pl-8 {
    padding-left: 2rem; }
  .sm\:pr-8 {
    padding-right: 2rem; }
  .sm\:-mb-9 {
    margin-bottom: -2.25rem; }
  .sm\:-mt-9 {
    margin-bottom: -2.25rem; }
  .sm\:-mr-9 {
    margin-right: -2.25rem; }
  .sm\:-ml-9 {
    margin-left: -2.25rem; }
  .sm\:mb-9 {
    margin-bottom: 2.25rem; }
  .sm\:mt-9 {
    margin-top: 2.25rem !important; }
  .sm\:ml-9 {
    margin-left: 2.25rem; }
  .sm\:mr-9 {
    margin-right: 2.25rem; }
  .sm\:pb-9 {
    padding-bottom: 2.25rem; }
  .sm\:pt-9 {
    padding-top: 2.25rem !important; }
  .sm\:pl-9 {
    padding-left: 2.25rem; }
  .sm\:pr-9 {
    padding-right: 2.25rem; }
  .sm\:-mb-10 {
    margin-bottom: -2.5rem; }
  .sm\:-mt-10 {
    margin-bottom: -2.5rem; }
  .sm\:-mr-10 {
    margin-right: -2.5rem; }
  .sm\:-ml-10 {
    margin-left: -2.5rem; }
  .sm\:mb-10 {
    margin-bottom: 2.5rem; }
  .sm\:mt-10 {
    margin-top: 2.5rem !important; }
  .sm\:ml-10 {
    margin-left: 2.5rem; }
  .sm\:mr-10 {
    margin-right: 2.5rem; }
  .sm\:pb-10 {
    padding-bottom: 2.5rem; }
  .sm\:pt-10 {
    padding-top: 2.5rem !important; }
  .sm\:pl-10 {
    padding-left: 2.5rem; }
  .sm\:pr-10 {
    padding-right: 2.5rem; }
  .sm\:-mb-11 {
    margin-bottom: -2.75rem; }
  .sm\:-mt-11 {
    margin-bottom: -2.75rem; }
  .sm\:-mr-11 {
    margin-right: -2.75rem; }
  .sm\:-ml-11 {
    margin-left: -2.75rem; }
  .sm\:mb-11 {
    margin-bottom: 2.75rem; }
  .sm\:mt-11 {
    margin-top: 2.75rem !important; }
  .sm\:ml-11 {
    margin-left: 2.75rem; }
  .sm\:mr-11 {
    margin-right: 2.75rem; }
  .sm\:pb-11 {
    padding-bottom: 2.75rem; }
  .sm\:pt-11 {
    padding-top: 2.75rem !important; }
  .sm\:pl-11 {
    padding-left: 2.75rem; }
  .sm\:pr-11 {
    padding-right: 2.75rem; }
  .sm\:-mb-12 {
    margin-bottom: -3rem; }
  .sm\:-mt-12 {
    margin-bottom: -3rem; }
  .sm\:-mr-12 {
    margin-right: -3rem; }
  .sm\:-ml-12 {
    margin-left: -3rem; }
  .sm\:mb-12 {
    margin-bottom: 3rem; }
  .sm\:mt-12 {
    margin-top: 3rem !important; }
  .sm\:ml-12 {
    margin-left: 3rem; }
  .sm\:mr-12 {
    margin-right: 3rem; }
  .sm\:pb-12 {
    padding-bottom: 3rem; }
  .sm\:pt-12 {
    padding-top: 3rem !important; }
  .sm\:pl-12 {
    padding-left: 3rem; }
  .sm\:pr-12 {
    padding-right: 3rem; }
  .sm\:-mb-13 {
    margin-bottom: -3.25rem; }
  .sm\:-mt-13 {
    margin-bottom: -3.25rem; }
  .sm\:-mr-13 {
    margin-right: -3.25rem; }
  .sm\:-ml-13 {
    margin-left: -3.25rem; }
  .sm\:mb-13 {
    margin-bottom: 3.25rem; }
  .sm\:mt-13 {
    margin-top: 3.25rem !important; }
  .sm\:ml-13 {
    margin-left: 3.25rem; }
  .sm\:mr-13 {
    margin-right: 3.25rem; }
  .sm\:pb-13 {
    padding-bottom: 3.25rem; }
  .sm\:pt-13 {
    padding-top: 3.25rem !important; }
  .sm\:pl-13 {
    padding-left: 3.25rem; }
  .sm\:pr-13 {
    padding-right: 3.25rem; }
  .sm\:-mb-14 {
    margin-bottom: -3.5rem; }
  .sm\:-mt-14 {
    margin-bottom: -3.5rem; }
  .sm\:-mr-14 {
    margin-right: -3.5rem; }
  .sm\:-ml-14 {
    margin-left: -3.5rem; }
  .sm\:mb-14 {
    margin-bottom: 3.5rem; }
  .sm\:mt-14 {
    margin-top: 3.5rem !important; }
  .sm\:ml-14 {
    margin-left: 3.5rem; }
  .sm\:mr-14 {
    margin-right: 3.5rem; }
  .sm\:pb-14 {
    padding-bottom: 3.5rem; }
  .sm\:pt-14 {
    padding-top: 3.5rem !important; }
  .sm\:pl-14 {
    padding-left: 3.5rem; }
  .sm\:pr-14 {
    padding-right: 3.5rem; }
  .sm\:-mb-15 {
    margin-bottom: -3.75rem; }
  .sm\:-mt-15 {
    margin-bottom: -3.75rem; }
  .sm\:-mr-15 {
    margin-right: -3.75rem; }
  .sm\:-ml-15 {
    margin-left: -3.75rem; }
  .sm\:mb-15 {
    margin-bottom: 3.75rem; }
  .sm\:mt-15 {
    margin-top: 3.75rem !important; }
  .sm\:ml-15 {
    margin-left: 3.75rem; }
  .sm\:mr-15 {
    margin-right: 3.75rem; }
  .sm\:pb-15 {
    padding-bottom: 3.75rem; }
  .sm\:pt-15 {
    padding-top: 3.75rem !important; }
  .sm\:pl-15 {
    padding-left: 3.75rem; }
  .sm\:pr-15 {
    padding-right: 3.75rem; } }

@media (min-width: 768px) {
  .md\:-mb-0 {
    margin-bottom: 0rem; }
  .md\:-mt-0 {
    margin-bottom: 0rem; }
  .md\:-mr-0 {
    margin-right: 0rem; }
  .md\:-ml-0 {
    margin-left: 0rem; }
  .md\:mb-0 {
    margin-bottom: 0rem; }
  .md\:mt-0 {
    margin-top: 0rem; }
  .md\:ml-0 {
    margin-left: 0rem; }
  .md\:mr-0 {
    margin-right: 0rem; }
  .md\:pb-0 {
    padding-bottom: 0rem; }
  .md\:pt-0 {
    padding-top: 0rem !important; }
  .md\:pl-0 {
    padding-left: 0rem; }
  .md\:pr-0 {
    padding-right: 0rem; }
  .md\:-mb-1 {
    margin-bottom: -0.25rem; }
  .md\:-mt-1 {
    margin-bottom: -0.25rem; }
  .md\:-mr-1 {
    margin-right: -0.25rem; }
  .md\:-ml-1 {
    margin-left: -0.25rem; }
  .md\:mb-1 {
    margin-bottom: 0.25rem; }
  .md\:mt-1 {
    margin-top: 0.25rem; }
  .md\:ml-1 {
    margin-left: 0.25rem; }
  .md\:mr-1 {
    margin-right: 0.25rem; }
  .md\:pb-1 {
    padding-bottom: 0.25rem; }
  .md\:pt-1 {
    padding-top: 0.25rem !important; }
  .md\:pl-1 {
    padding-left: 0.25rem; }
  .md\:pr-1 {
    padding-right: 0.25rem; }
  .md\:-mb-2 {
    margin-bottom: -0.5rem; }
  .md\:-mt-2 {
    margin-bottom: -0.5rem; }
  .md\:-mr-2 {
    margin-right: -0.5rem; }
  .md\:-ml-2 {
    margin-left: -0.5rem; }
  .md\:mb-2 {
    margin-bottom: 0.5rem; }
  .md\:mt-2 {
    margin-top: 0.5rem; }
  .md\:ml-2 {
    margin-left: 0.5rem; }
  .md\:mr-2 {
    margin-right: 0.5rem; }
  .md\:pb-2 {
    padding-bottom: 0.5rem; }
  .md\:pt-2 {
    padding-top: 0.5rem !important; }
  .md\:pl-2 {
    padding-left: 0.5rem; }
  .md\:pr-2 {
    padding-right: 0.5rem; }
  .md\:-mb-3 {
    margin-bottom: -0.75rem; }
  .md\:-mt-3 {
    margin-bottom: -0.75rem; }
  .md\:-mr-3 {
    margin-right: -0.75rem; }
  .md\:-ml-3 {
    margin-left: -0.75rem; }
  .md\:mb-3 {
    margin-bottom: 0.75rem; }
  .md\:mt-3 {
    margin-top: 0.75rem; }
  .md\:ml-3 {
    margin-left: 0.75rem; }
  .md\:mr-3 {
    margin-right: 0.75rem; }
  .md\:pb-3 {
    padding-bottom: 0.75rem; }
  .md\:pt-3 {
    padding-top: 0.75rem !important; }
  .md\:pl-3 {
    padding-left: 0.75rem; }
  .md\:pr-3 {
    padding-right: 0.75rem; }
  .md\:-mb-4 {
    margin-bottom: -1rem; }
  .md\:-mt-4 {
    margin-bottom: -1rem; }
  .md\:-mr-4 {
    margin-right: -1rem; }
  .md\:-ml-4 {
    margin-left: -1rem; }
  .md\:mb-4 {
    margin-bottom: 1rem; }
  .md\:mt-4 {
    margin-top: 1rem; }
  .md\:ml-4 {
    margin-left: 1rem; }
  .md\:mr-4 {
    margin-right: 1rem; }
  .md\:pb-4 {
    padding-bottom: 1rem; }
  .md\:pt-4 {
    padding-top: 1rem !important; }
  .md\:pl-4 {
    padding-left: 1rem; }
  .md\:pr-4 {
    padding-right: 1rem; }
  .md\:-mb-5 {
    margin-bottom: -1.25rem; }
  .md\:-mt-5 {
    margin-bottom: -1.25rem; }
  .md\:-mr-5 {
    margin-right: -1.25rem; }
  .md\:-ml-5 {
    margin-left: -1.25rem; }
  .md\:mb-5 {
    margin-bottom: 1.25rem; }
  .md\:mt-5 {
    margin-top: 1.25rem; }
  .md\:ml-5 {
    margin-left: 1.25rem; }
  .md\:mr-5 {
    margin-right: 1.25rem; }
  .md\:pb-5 {
    padding-bottom: 1.25rem; }
  .md\:pt-5 {
    padding-top: 1.25rem !important; }
  .md\:pl-5 {
    padding-left: 1.25rem; }
  .md\:pr-5 {
    padding-right: 1.25rem; }
  .md\:-mb-6 {
    margin-bottom: -1.5rem; }
  .md\:-mt-6 {
    margin-bottom: -1.5rem; }
  .md\:-mr-6 {
    margin-right: -1.5rem; }
  .md\:-ml-6 {
    margin-left: -1.5rem; }
  .md\:mb-6 {
    margin-bottom: 1.5rem; }
  .md\:mt-6 {
    margin-top: 1.5rem; }
  .md\:ml-6 {
    margin-left: 1.5rem; }
  .md\:mr-6 {
    margin-right: 1.5rem; }
  .md\:pb-6 {
    padding-bottom: 1.5rem; }
  .md\:pt-6 {
    padding-top: 1.5rem !important; }
  .md\:pl-6 {
    padding-left: 1.5rem; }
  .md\:pr-6 {
    padding-right: 1.5rem; }
  .md\:-mb-7 {
    margin-bottom: -1.75rem; }
  .md\:-mt-7 {
    margin-bottom: -1.75rem; }
  .md\:-mr-7 {
    margin-right: -1.75rem; }
  .md\:-ml-7 {
    margin-left: -1.75rem; }
  .md\:mb-7 {
    margin-bottom: 1.75rem; }
  .md\:mt-7 {
    margin-top: 1.75rem; }
  .md\:ml-7 {
    margin-left: 1.75rem; }
  .md\:mr-7 {
    margin-right: 1.75rem; }
  .md\:pb-7 {
    padding-bottom: 1.75rem; }
  .md\:pt-7 {
    padding-top: 1.75rem !important; }
  .md\:pl-7 {
    padding-left: 1.75rem; }
  .md\:pr-7 {
    padding-right: 1.75rem; }
  .md\:-mb-8 {
    margin-bottom: -2rem; }
  .md\:-mt-8 {
    margin-bottom: -2rem; }
  .md\:-mr-8 {
    margin-right: -2rem; }
  .md\:-ml-8 {
    margin-left: -2rem; }
  .md\:mb-8 {
    margin-bottom: 2rem; }
  .md\:mt-8 {
    margin-top: 2rem; }
  .md\:ml-8 {
    margin-left: 2rem; }
  .md\:mr-8 {
    margin-right: 2rem; }
  .md\:pb-8 {
    padding-bottom: 2rem; }
  .md\:pt-8 {
    padding-top: 2rem !important; }
  .md\:pl-8 {
    padding-left: 2rem; }
  .md\:pr-8 {
    padding-right: 2rem; }
  .md\:-mb-9 {
    margin-bottom: -2.25rem; }
  .md\:-mt-9 {
    margin-bottom: -2.25rem; }
  .md\:-mr-9 {
    margin-right: -2.25rem; }
  .md\:-ml-9 {
    margin-left: -2.25rem; }
  .md\:mb-9 {
    margin-bottom: 2.25rem; }
  .md\:mt-9 {
    margin-top: 2.25rem; }
  .md\:ml-9 {
    margin-left: 2.25rem; }
  .md\:mr-9 {
    margin-right: 2.25rem; }
  .md\:pb-9 {
    padding-bottom: 2.25rem; }
  .md\:pt-9 {
    padding-top: 2.25rem !important; }
  .md\:pl-9 {
    padding-left: 2.25rem; }
  .md\:pr-9 {
    padding-right: 2.25rem; }
  .md\:-mb-10 {
    margin-bottom: -2.5rem; }
  .md\:-mt-10 {
    margin-bottom: -2.5rem; }
  .md\:-mr-10 {
    margin-right: -2.5rem; }
  .md\:-ml-10 {
    margin-left: -2.5rem; }
  .md\:mb-10 {
    margin-bottom: 2.5rem; }
  .md\:mt-10 {
    margin-top: 2.5rem; }
  .md\:ml-10 {
    margin-left: 2.5rem; }
  .md\:mr-10 {
    margin-right: 2.5rem; }
  .md\:pb-10 {
    padding-bottom: 2.5rem; }
  .md\:pt-10 {
    padding-top: 2.5rem !important; }
  .md\:pl-10 {
    padding-left: 2.5rem; }
  .md\:pr-10 {
    padding-right: 2.5rem; }
  .md\:-mb-11 {
    margin-bottom: -2.75rem; }
  .md\:-mt-11 {
    margin-bottom: -2.75rem; }
  .md\:-mr-11 {
    margin-right: -2.75rem; }
  .md\:-ml-11 {
    margin-left: -2.75rem; }
  .md\:mb-11 {
    margin-bottom: 2.75rem; }
  .md\:mt-11 {
    margin-top: 2.75rem; }
  .md\:ml-11 {
    margin-left: 2.75rem; }
  .md\:mr-11 {
    margin-right: 2.75rem; }
  .md\:pb-11 {
    padding-bottom: 2.75rem; }
  .md\:pt-11 {
    padding-top: 2.75rem !important; }
  .md\:pl-11 {
    padding-left: 2.75rem; }
  .md\:pr-11 {
    padding-right: 2.75rem; }
  .md\:-mb-12 {
    margin-bottom: -3rem; }
  .md\:-mt-12 {
    margin-bottom: -3rem; }
  .md\:-mr-12 {
    margin-right: -3rem; }
  .md\:-ml-12 {
    margin-left: -3rem; }
  .md\:mb-12 {
    margin-bottom: 3rem; }
  .md\:mt-12 {
    margin-top: 3rem; }
  .md\:ml-12 {
    margin-left: 3rem; }
  .md\:mr-12 {
    margin-right: 3rem; }
  .md\:pb-12 {
    padding-bottom: 3rem; }
  .md\:pt-12 {
    padding-top: 3rem !important; }
  .md\:pl-12 {
    padding-left: 3rem; }
  .md\:pr-12 {
    padding-right: 3rem; }
  .md\:-mb-13 {
    margin-bottom: -3.25rem; }
  .md\:-mt-13 {
    margin-bottom: -3.25rem; }
  .md\:-mr-13 {
    margin-right: -3.25rem; }
  .md\:-ml-13 {
    margin-left: -3.25rem; }
  .md\:mb-13 {
    margin-bottom: 3.25rem; }
  .md\:mt-13 {
    margin-top: 3.25rem; }
  .md\:ml-13 {
    margin-left: 3.25rem; }
  .md\:mr-13 {
    margin-right: 3.25rem; }
  .md\:pb-13 {
    padding-bottom: 3.25rem; }
  .md\:pt-13 {
    padding-top: 3.25rem !important; }
  .md\:pl-13 {
    padding-left: 3.25rem; }
  .md\:pr-13 {
    padding-right: 3.25rem; }
  .md\:-mb-14 {
    margin-bottom: -3.5rem; }
  .md\:-mt-14 {
    margin-bottom: -3.5rem; }
  .md\:-mr-14 {
    margin-right: -3.5rem; }
  .md\:-ml-14 {
    margin-left: -3.5rem; }
  .md\:mb-14 {
    margin-bottom: 3.5rem; }
  .md\:mt-14 {
    margin-top: 3.5rem; }
  .md\:ml-14 {
    margin-left: 3.5rem; }
  .md\:mr-14 {
    margin-right: 3.5rem; }
  .md\:pb-14 {
    padding-bottom: 3.5rem; }
  .md\:pt-14 {
    padding-top: 3.5rem !important; }
  .md\:pl-14 {
    padding-left: 3.5rem; }
  .md\:pr-14 {
    padding-right: 3.5rem; }
  .md\:-mb-15 {
    margin-bottom: -3.75rem; }
  .md\:-mt-15 {
    margin-bottom: -3.75rem; }
  .md\:-mr-15 {
    margin-right: -3.75rem; }
  .md\:-ml-15 {
    margin-left: -3.75rem; }
  .md\:mb-15 {
    margin-bottom: 3.75rem; }
  .md\:mt-15 {
    margin-top: 3.75rem; }
  .md\:ml-15 {
    margin-left: 3.75rem; }
  .md\:mr-15 {
    margin-right: 3.75rem; }
  .md\:pb-15 {
    padding-bottom: 3.75rem; }
  .md\:pt-15 {
    padding-top: 3.75rem !important; }
  .md\:pl-15 {
    padding-left: 3.75rem; }
  .md\:pr-15 {
    padding-right: 3.75rem; } }

@media (min-width: 992px) {
  .lg\:-mb-0 {
    margin-bottom: 0rem; }
  .lg\:-mt-0 {
    margin-bottom: 0rem; }
  .lg\:-mr-0 {
    margin-right: 0rem; }
  .lg\:-ml-0 {
    margin-left: 0rem; }
  .lg\:mb-0 {
    margin-bottom: 0rem; }
  .lg\:mt-0 {
    margin-top: 0rem; }
  .lg\:ml-0 {
    margin-left: 0rem; }
  .lg\:mr-0 {
    margin-right: 0rem; }
  .lg\:pb-0 {
    padding-bottom: 0rem; }
  .lg\:pt-0 {
    padding-top: 0rem !important; }
  .lg\:pl-0 {
    padding-left: 0rem; }
  .lg\:pr-0 {
    padding-right: 0rem; }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem; }
  .lg\:-mt-1 {
    margin-bottom: -0.25rem; }
  .lg\:-mr-1 {
    margin-right: -0.25rem; }
  .lg\:-ml-1 {
    margin-left: -0.25rem; }
  .lg\:mb-1 {
    margin-bottom: 0.25rem; }
  .lg\:mt-1 {
    margin-top: 0.25rem; }
  .lg\:ml-1 {
    margin-left: 0.25rem; }
  .lg\:mr-1 {
    margin-right: 0.25rem; }
  .lg\:pb-1 {
    padding-bottom: 0.25rem; }
  .lg\:pt-1 {
    padding-top: 0.25rem !important; }
  .lg\:pl-1 {
    padding-left: 0.25rem; }
  .lg\:pr-1 {
    padding-right: 0.25rem; }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem; }
  .lg\:-mt-2 {
    margin-bottom: -0.5rem; }
  .lg\:-mr-2 {
    margin-right: -0.5rem; }
  .lg\:-ml-2 {
    margin-left: -0.5rem; }
  .lg\:mb-2 {
    margin-bottom: 0.5rem; }
  .lg\:mt-2 {
    margin-top: 0.5rem; }
  .lg\:ml-2 {
    margin-left: 0.5rem; }
  .lg\:mr-2 {
    margin-right: 0.5rem; }
  .lg\:pb-2 {
    padding-bottom: 0.5rem; }
  .lg\:pt-2 {
    padding-top: 0.5rem !important; }
  .lg\:pl-2 {
    padding-left: 0.5rem; }
  .lg\:pr-2 {
    padding-right: 0.5rem; }
  .lg\:-mb-3 {
    margin-bottom: -0.75rem; }
  .lg\:-mt-3 {
    margin-bottom: -0.75rem; }
  .lg\:-mr-3 {
    margin-right: -0.75rem; }
  .lg\:-ml-3 {
    margin-left: -0.75rem; }
  .lg\:mb-3 {
    margin-bottom: 0.75rem; }
  .lg\:mt-3 {
    margin-top: 0.75rem; }
  .lg\:ml-3 {
    margin-left: 0.75rem; }
  .lg\:mr-3 {
    margin-right: 0.75rem; }
  .lg\:pb-3 {
    padding-bottom: 0.75rem; }
  .lg\:pt-3 {
    padding-top: 0.75rem !important; }
  .lg\:pl-3 {
    padding-left: 0.75rem; }
  .lg\:pr-3 {
    padding-right: 0.75rem; }
  .lg\:-mb-4 {
    margin-bottom: -1rem; }
  .lg\:-mt-4 {
    margin-bottom: -1rem; }
  .lg\:-mr-4 {
    margin-right: -1rem; }
  .lg\:-ml-4 {
    margin-left: -1rem; }
  .lg\:mb-4 {
    margin-bottom: 1rem; }
  .lg\:mt-4 {
    margin-top: 1rem; }
  .lg\:ml-4 {
    margin-left: 1rem; }
  .lg\:mr-4 {
    margin-right: 1rem; }
  .lg\:pb-4 {
    padding-bottom: 1rem; }
  .lg\:pt-4 {
    padding-top: 1rem !important; }
  .lg\:pl-4 {
    padding-left: 1rem; }
  .lg\:pr-4 {
    padding-right: 1rem; }
  .lg\:-mb-5 {
    margin-bottom: -1.25rem; }
  .lg\:-mt-5 {
    margin-bottom: -1.25rem; }
  .lg\:-mr-5 {
    margin-right: -1.25rem; }
  .lg\:-ml-5 {
    margin-left: -1.25rem; }
  .lg\:mb-5 {
    margin-bottom: 1.25rem; }
  .lg\:mt-5 {
    margin-top: 1.25rem; }
  .lg\:ml-5 {
    margin-left: 1.25rem; }
  .lg\:mr-5 {
    margin-right: 1.25rem; }
  .lg\:pb-5 {
    padding-bottom: 1.25rem; }
  .lg\:pt-5 {
    padding-top: 1.25rem !important; }
  .lg\:pl-5 {
    padding-left: 1.25rem; }
  .lg\:pr-5 {
    padding-right: 1.25rem; }
  .lg\:-mb-6 {
    margin-bottom: -1.5rem; }
  .lg\:-mt-6 {
    margin-bottom: -1.5rem; }
  .lg\:-mr-6 {
    margin-right: -1.5rem; }
  .lg\:-ml-6 {
    margin-left: -1.5rem; }
  .lg\:mb-6 {
    margin-bottom: 1.5rem; }
  .lg\:mt-6 {
    margin-top: 1.5rem; }
  .lg\:ml-6 {
    margin-left: 1.5rem; }
  .lg\:mr-6 {
    margin-right: 1.5rem; }
  .lg\:pb-6 {
    padding-bottom: 1.5rem; }
  .lg\:pt-6 {
    padding-top: 1.5rem !important; }
  .lg\:pl-6 {
    padding-left: 1.5rem; }
  .lg\:pr-6 {
    padding-right: 1.5rem; }
  .lg\:-mb-7 {
    margin-bottom: -1.75rem; }
  .lg\:-mt-7 {
    margin-bottom: -1.75rem; }
  .lg\:-mr-7 {
    margin-right: -1.75rem; }
  .lg\:-ml-7 {
    margin-left: -1.75rem; }
  .lg\:mb-7 {
    margin-bottom: 1.75rem; }
  .lg\:mt-7 {
    margin-top: 1.75rem; }
  .lg\:ml-7 {
    margin-left: 1.75rem; }
  .lg\:mr-7 {
    margin-right: 1.75rem; }
  .lg\:pb-7 {
    padding-bottom: 1.75rem; }
  .lg\:pt-7 {
    padding-top: 1.75rem !important; }
  .lg\:pl-7 {
    padding-left: 1.75rem; }
  .lg\:pr-7 {
    padding-right: 1.75rem; }
  .lg\:-mb-8 {
    margin-bottom: -2rem; }
  .lg\:-mt-8 {
    margin-bottom: -2rem; }
  .lg\:-mr-8 {
    margin-right: -2rem; }
  .lg\:-ml-8 {
    margin-left: -2rem; }
  .lg\:mb-8 {
    margin-bottom: 2rem; }
  .lg\:mt-8 {
    margin-top: 2rem; }
  .lg\:ml-8 {
    margin-left: 2rem; }
  .lg\:mr-8 {
    margin-right: 2rem; }
  .lg\:pb-8 {
    padding-bottom: 2rem; }
  .lg\:pt-8 {
    padding-top: 2rem !important; }
  .lg\:pl-8 {
    padding-left: 2rem; }
  .lg\:pr-8 {
    padding-right: 2rem; }
  .lg\:-mb-9 {
    margin-bottom: -2.25rem; }
  .lg\:-mt-9 {
    margin-bottom: -2.25rem; }
  .lg\:-mr-9 {
    margin-right: -2.25rem; }
  .lg\:-ml-9 {
    margin-left: -2.25rem; }
  .lg\:mb-9 {
    margin-bottom: 2.25rem; }
  .lg\:mt-9 {
    margin-top: 2.25rem; }
  .lg\:ml-9 {
    margin-left: 2.25rem; }
  .lg\:mr-9 {
    margin-right: 2.25rem; }
  .lg\:pb-9 {
    padding-bottom: 2.25rem; }
  .lg\:pt-9 {
    padding-top: 2.25rem !important; }
  .lg\:pl-9 {
    padding-left: 2.25rem; }
  .lg\:pr-9 {
    padding-right: 2.25rem; }
  .lg\:-mb-10 {
    margin-bottom: -2.5rem; }
  .lg\:-mt-10 {
    margin-bottom: -2.5rem; }
  .lg\:-mr-10 {
    margin-right: -2.5rem; }
  .lg\:-ml-10 {
    margin-left: -2.5rem; }
  .lg\:mb-10 {
    margin-bottom: 2.5rem; }
  .lg\:mt-10 {
    margin-top: 2.5rem; }
  .lg\:ml-10 {
    margin-left: 2.5rem; }
  .lg\:mr-10 {
    margin-right: 2.5rem; }
  .lg\:pb-10 {
    padding-bottom: 2.5rem; }
  .lg\:pt-10 {
    padding-top: 2.5rem !important; }
  .lg\:pl-10 {
    padding-left: 2.5rem; }
  .lg\:pr-10 {
    padding-right: 2.5rem; }
  .lg\:-mb-11 {
    margin-bottom: -2.75rem; }
  .lg\:-mt-11 {
    margin-bottom: -2.75rem; }
  .lg\:-mr-11 {
    margin-right: -2.75rem; }
  .lg\:-ml-11 {
    margin-left: -2.75rem; }
  .lg\:mb-11 {
    margin-bottom: 2.75rem; }
  .lg\:mt-11 {
    margin-top: 2.75rem; }
  .lg\:ml-11 {
    margin-left: 2.75rem; }
  .lg\:mr-11 {
    margin-right: 2.75rem; }
  .lg\:pb-11 {
    padding-bottom: 2.75rem; }
  .lg\:pt-11 {
    padding-top: 2.75rem !important; }
  .lg\:pl-11 {
    padding-left: 2.75rem; }
  .lg\:pr-11 {
    padding-right: 2.75rem; }
  .lg\:-mb-12 {
    margin-bottom: -3rem; }
  .lg\:-mt-12 {
    margin-bottom: -3rem; }
  .lg\:-mr-12 {
    margin-right: -3rem; }
  .lg\:-ml-12 {
    margin-left: -3rem; }
  .lg\:mb-12 {
    margin-bottom: 3rem; }
  .lg\:mt-12 {
    margin-top: 3rem; }
  .lg\:ml-12 {
    margin-left: 3rem; }
  .lg\:mr-12 {
    margin-right: 3rem; }
  .lg\:pb-12 {
    padding-bottom: 3rem; }
  .lg\:pt-12 {
    padding-top: 3rem !important; }
  .lg\:pl-12 {
    padding-left: 3rem; }
  .lg\:pr-12 {
    padding-right: 3rem; }
  .lg\:-mb-13 {
    margin-bottom: -3.25rem; }
  .lg\:-mt-13 {
    margin-bottom: -3.25rem; }
  .lg\:-mr-13 {
    margin-right: -3.25rem; }
  .lg\:-ml-13 {
    margin-left: -3.25rem; }
  .lg\:mb-13 {
    margin-bottom: 3.25rem; }
  .lg\:mt-13 {
    margin-top: 3.25rem; }
  .lg\:ml-13 {
    margin-left: 3.25rem; }
  .lg\:mr-13 {
    margin-right: 3.25rem; }
  .lg\:pb-13 {
    padding-bottom: 3.25rem; }
  .lg\:pt-13 {
    padding-top: 3.25rem !important; }
  .lg\:pl-13 {
    padding-left: 3.25rem; }
  .lg\:pr-13 {
    padding-right: 3.25rem; }
  .lg\:-mb-14 {
    margin-bottom: -3.5rem; }
  .lg\:-mt-14 {
    margin-bottom: -3.5rem; }
  .lg\:-mr-14 {
    margin-right: -3.5rem; }
  .lg\:-ml-14 {
    margin-left: -3.5rem; }
  .lg\:mb-14 {
    margin-bottom: 3.5rem; }
  .lg\:mt-14 {
    margin-top: 3.5rem; }
  .lg\:ml-14 {
    margin-left: 3.5rem; }
  .lg\:mr-14 {
    margin-right: 3.5rem; }
  .lg\:pb-14 {
    padding-bottom: 3.5rem; }
  .lg\:pt-14 {
    padding-top: 3.5rem !important; }
  .lg\:pl-14 {
    padding-left: 3.5rem; }
  .lg\:pr-14 {
    padding-right: 3.5rem; }
  .lg\:-mb-15 {
    margin-bottom: -3.75rem; }
  .lg\:-mt-15 {
    margin-bottom: -3.75rem; }
  .lg\:-mr-15 {
    margin-right: -3.75rem; }
  .lg\:-ml-15 {
    margin-left: -3.75rem; }
  .lg\:mb-15 {
    margin-bottom: 3.75rem; }
  .lg\:mt-15 {
    margin-top: 3.75rem; }
  .lg\:ml-15 {
    margin-left: 3.75rem; }
  .lg\:mr-15 {
    margin-right: 3.75rem; }
  .lg\:pb-15 {
    padding-bottom: 3.75rem; }
  .lg\:pt-15 {
    padding-top: 3.75rem !important; }
  .lg\:pl-15 {
    padding-left: 3.75rem; }
  .lg\:pr-15 {
    padding-right: 3.75rem; } }

@media (min-width: 1200px) {
  .xl\:-mb-0 {
    margin-bottom: 0rem; }
  .xl\:-mt-0 {
    margin-bottom: 0rem; }
  .xl\:-mr-0 {
    margin-right: 0rem; }
  .xl\:-ml-0 {
    margin-left: 0rem; }
  .xl\:mb-0 {
    margin-bottom: 0rem; }
  .xl\:mt-0 {
    margin-top: 0rem !important; }
  .xl\:ml-0 {
    margin-left: 0rem; }
  .xl\:mr-0 {
    margin-right: 0rem; }
  .xl\:pb-0 {
    padding-bottom: 0rem; }
  .xl\:pt-0 {
    padding-top: 0rem !important; }
  .xl\:pl-0 {
    padding-left: 0rem; }
  .xl\:pr-0 {
    padding-right: 0rem; }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem; }
  .xl\:-mt-1 {
    margin-bottom: -0.25rem; }
  .xl\:-mr-1 {
    margin-right: -0.25rem; }
  .xl\:-ml-1 {
    margin-left: -0.25rem; }
  .xl\:mb-1 {
    margin-bottom: 0.25rem; }
  .xl\:mt-1 {
    margin-top: 0.25rem !important; }
  .xl\:ml-1 {
    margin-left: 0.25rem; }
  .xl\:mr-1 {
    margin-right: 0.25rem; }
  .xl\:pb-1 {
    padding-bottom: 0.25rem; }
  .xl\:pt-1 {
    padding-top: 0.25rem !important; }
  .xl\:pl-1 {
    padding-left: 0.25rem; }
  .xl\:pr-1 {
    padding-right: 0.25rem; }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem; }
  .xl\:-mt-2 {
    margin-bottom: -0.5rem; }
  .xl\:-mr-2 {
    margin-right: -0.5rem; }
  .xl\:-ml-2 {
    margin-left: -0.5rem; }
  .xl\:mb-2 {
    margin-bottom: 0.5rem; }
  .xl\:mt-2 {
    margin-top: 0.5rem !important; }
  .xl\:ml-2 {
    margin-left: 0.5rem; }
  .xl\:mr-2 {
    margin-right: 0.5rem; }
  .xl\:pb-2 {
    padding-bottom: 0.5rem; }
  .xl\:pt-2 {
    padding-top: 0.5rem !important; }
  .xl\:pl-2 {
    padding-left: 0.5rem; }
  .xl\:pr-2 {
    padding-right: 0.5rem; }
  .xl\:-mb-3 {
    margin-bottom: -0.75rem; }
  .xl\:-mt-3 {
    margin-bottom: -0.75rem; }
  .xl\:-mr-3 {
    margin-right: -0.75rem; }
  .xl\:-ml-3 {
    margin-left: -0.75rem; }
  .xl\:mb-3 {
    margin-bottom: 0.75rem; }
  .xl\:mt-3 {
    margin-top: 0.75rem !important; }
  .xl\:ml-3 {
    margin-left: 0.75rem; }
  .xl\:mr-3 {
    margin-right: 0.75rem; }
  .xl\:pb-3 {
    padding-bottom: 0.75rem; }
  .xl\:pt-3 {
    padding-top: 0.75rem !important; }
  .xl\:pl-3 {
    padding-left: 0.75rem; }
  .xl\:pr-3 {
    padding-right: 0.75rem; }
  .xl\:-mb-4 {
    margin-bottom: -1rem; }
  .xl\:-mt-4 {
    margin-bottom: -1rem; }
  .xl\:-mr-4 {
    margin-right: -1rem; }
  .xl\:-ml-4 {
    margin-left: -1rem; }
  .xl\:mb-4 {
    margin-bottom: 1rem; }
  .xl\:mt-4 {
    margin-top: 1rem !important; }
  .xl\:ml-4 {
    margin-left: 1rem; }
  .xl\:mr-4 {
    margin-right: 1rem; }
  .xl\:pb-4 {
    padding-bottom: 1rem; }
  .xl\:pt-4 {
    padding-top: 1rem !important; }
  .xl\:pl-4 {
    padding-left: 1rem; }
  .xl\:pr-4 {
    padding-right: 1rem; }
  .xl\:-mb-5 {
    margin-bottom: -1.25rem; }
  .xl\:-mt-5 {
    margin-bottom: -1.25rem; }
  .xl\:-mr-5 {
    margin-right: -1.25rem; }
  .xl\:-ml-5 {
    margin-left: -1.25rem; }
  .xl\:mb-5 {
    margin-bottom: 1.25rem; }
  .xl\:mt-5 {
    margin-top: 1.25rem !important; }
  .xl\:ml-5 {
    margin-left: 1.25rem; }
  .xl\:mr-5 {
    margin-right: 1.25rem; }
  .xl\:pb-5 {
    padding-bottom: 1.25rem; }
  .xl\:pt-5 {
    padding-top: 1.25rem !important; }
  .xl\:pl-5 {
    padding-left: 1.25rem; }
  .xl\:pr-5 {
    padding-right: 1.25rem; }
  .xl\:-mb-6 {
    margin-bottom: -1.5rem; }
  .xl\:-mt-6 {
    margin-bottom: -1.5rem; }
  .xl\:-mr-6 {
    margin-right: -1.5rem; }
  .xl\:-ml-6 {
    margin-left: -1.5rem; }
  .xl\:mb-6 {
    margin-bottom: 1.5rem; }
  .xl\:mt-6 {
    margin-top: 1.5rem !important; }
  .xl\:ml-6 {
    margin-left: 1.5rem; }
  .xl\:mr-6 {
    margin-right: 1.5rem; }
  .xl\:pb-6 {
    padding-bottom: 1.5rem; }
  .xl\:pt-6 {
    padding-top: 1.5rem !important; }
  .xl\:pl-6 {
    padding-left: 1.5rem; }
  .xl\:pr-6 {
    padding-right: 1.5rem; }
  .xl\:-mb-7 {
    margin-bottom: -1.75rem; }
  .xl\:-mt-7 {
    margin-bottom: -1.75rem; }
  .xl\:-mr-7 {
    margin-right: -1.75rem; }
  .xl\:-ml-7 {
    margin-left: -1.75rem; }
  .xl\:mb-7 {
    margin-bottom: 1.75rem; }
  .xl\:mt-7 {
    margin-top: 1.75rem !important; }
  .xl\:ml-7 {
    margin-left: 1.75rem; }
  .xl\:mr-7 {
    margin-right: 1.75rem; }
  .xl\:pb-7 {
    padding-bottom: 1.75rem; }
  .xl\:pt-7 {
    padding-top: 1.75rem !important; }
  .xl\:pl-7 {
    padding-left: 1.75rem; }
  .xl\:pr-7 {
    padding-right: 1.75rem; }
  .xl\:-mb-8 {
    margin-bottom: -2rem; }
  .xl\:-mt-8 {
    margin-bottom: -2rem; }
  .xl\:-mr-8 {
    margin-right: -2rem; }
  .xl\:-ml-8 {
    margin-left: -2rem; }
  .xl\:mb-8 {
    margin-bottom: 2rem; }
  .xl\:mt-8 {
    margin-top: 2rem !important; }
  .xl\:ml-8 {
    margin-left: 2rem; }
  .xl\:mr-8 {
    margin-right: 2rem; }
  .xl\:pb-8 {
    padding-bottom: 2rem; }
  .xl\:pt-8 {
    padding-top: 2rem !important; }
  .xl\:pl-8 {
    padding-left: 2rem; }
  .xl\:pr-8 {
    padding-right: 2rem; }
  .xl\:-mb-9 {
    margin-bottom: -2.25rem; }
  .xl\:-mt-9 {
    margin-bottom: -2.25rem; }
  .xl\:-mr-9 {
    margin-right: -2.25rem; }
  .xl\:-ml-9 {
    margin-left: -2.25rem; }
  .xl\:mb-9 {
    margin-bottom: 2.25rem; }
  .xl\:mt-9 {
    margin-top: 2.25rem !important; }
  .xl\:ml-9 {
    margin-left: 2.25rem; }
  .xl\:mr-9 {
    margin-right: 2.25rem; }
  .xl\:pb-9 {
    padding-bottom: 2.25rem; }
  .xl\:pt-9 {
    padding-top: 2.25rem !important; }
  .xl\:pl-9 {
    padding-left: 2.25rem; }
  .xl\:pr-9 {
    padding-right: 2.25rem; }
  .xl\:-mb-10 {
    margin-bottom: -2.5rem; }
  .xl\:-mt-10 {
    margin-bottom: -2.5rem; }
  .xl\:-mr-10 {
    margin-right: -2.5rem; }
  .xl\:-ml-10 {
    margin-left: -2.5rem; }
  .xl\:mb-10 {
    margin-bottom: 2.5rem; }
  .xl\:mt-10 {
    margin-top: 2.5rem !important; }
  .xl\:ml-10 {
    margin-left: 2.5rem; }
  .xl\:mr-10 {
    margin-right: 2.5rem; }
  .xl\:pb-10 {
    padding-bottom: 2.5rem; }
  .xl\:pt-10 {
    padding-top: 2.5rem !important; }
  .xl\:pl-10 {
    padding-left: 2.5rem; }
  .xl\:pr-10 {
    padding-right: 2.5rem; }
  .xl\:-mb-11 {
    margin-bottom: -2.75rem; }
  .xl\:-mt-11 {
    margin-bottom: -2.75rem; }
  .xl\:-mr-11 {
    margin-right: -2.75rem; }
  .xl\:-ml-11 {
    margin-left: -2.75rem; }
  .xl\:mb-11 {
    margin-bottom: 2.75rem; }
  .xl\:mt-11 {
    margin-top: 2.75rem !important; }
  .xl\:ml-11 {
    margin-left: 2.75rem; }
  .xl\:mr-11 {
    margin-right: 2.75rem; }
  .xl\:pb-11 {
    padding-bottom: 2.75rem; }
  .xl\:pt-11 {
    padding-top: 2.75rem !important; }
  .xl\:pl-11 {
    padding-left: 2.75rem; }
  .xl\:pr-11 {
    padding-right: 2.75rem; }
  .xl\:-mb-12 {
    margin-bottom: -3rem; }
  .xl\:-mt-12 {
    margin-bottom: -3rem; }
  .xl\:-mr-12 {
    margin-right: -3rem; }
  .xl\:-ml-12 {
    margin-left: -3rem; }
  .xl\:mb-12 {
    margin-bottom: 3rem; }
  .xl\:mt-12 {
    margin-top: 3rem !important; }
  .xl\:ml-12 {
    margin-left: 3rem; }
  .xl\:mr-12 {
    margin-right: 3rem; }
  .xl\:pb-12 {
    padding-bottom: 3rem; }
  .xl\:pt-12 {
    padding-top: 3rem !important; }
  .xl\:pl-12 {
    padding-left: 3rem; }
  .xl\:pr-12 {
    padding-right: 3rem; }
  .xl\:-mb-13 {
    margin-bottom: -3.25rem; }
  .xl\:-mt-13 {
    margin-bottom: -3.25rem; }
  .xl\:-mr-13 {
    margin-right: -3.25rem; }
  .xl\:-ml-13 {
    margin-left: -3.25rem; }
  .xl\:mb-13 {
    margin-bottom: 3.25rem; }
  .xl\:mt-13 {
    margin-top: 3.25rem !important; }
  .xl\:ml-13 {
    margin-left: 3.25rem; }
  .xl\:mr-13 {
    margin-right: 3.25rem; }
  .xl\:pb-13 {
    padding-bottom: 3.25rem; }
  .xl\:pt-13 {
    padding-top: 3.25rem !important; }
  .xl\:pl-13 {
    padding-left: 3.25rem; }
  .xl\:pr-13 {
    padding-right: 3.25rem; }
  .xl\:-mb-14 {
    margin-bottom: -3.5rem; }
  .xl\:-mt-14 {
    margin-bottom: -3.5rem; }
  .xl\:-mr-14 {
    margin-right: -3.5rem; }
  .xl\:-ml-14 {
    margin-left: -3.5rem; }
  .xl\:mb-14 {
    margin-bottom: 3.5rem; }
  .xl\:mt-14 {
    margin-top: 3.5rem !important; }
  .xl\:ml-14 {
    margin-left: 3.5rem; }
  .xl\:mr-14 {
    margin-right: 3.5rem; }
  .xl\:pb-14 {
    padding-bottom: 3.5rem; }
  .xl\:pt-14 {
    padding-top: 3.5rem !important; }
  .xl\:pl-14 {
    padding-left: 3.5rem; }
  .xl\:pr-14 {
    padding-right: 3.5rem; }
  .xl\:-mb-15 {
    margin-bottom: -3.75rem; }
  .xl\:-mt-15 {
    margin-bottom: -3.75rem; }
  .xl\:-mr-15 {
    margin-right: -3.75rem; }
  .xl\:-ml-15 {
    margin-left: -3.75rem; }
  .xl\:mb-15 {
    margin-bottom: 3.75rem; }
  .xl\:mt-15 {
    margin-top: 3.75rem !important; }
  .xl\:ml-15 {
    margin-left: 3.75rem; }
  .xl\:mr-15 {
    margin-right: 3.75rem; }
  .xl\:pb-15 {
    padding-bottom: 3.75rem; }
  .xl\:pt-15 {
    padding-top: 3.75rem !important; }
  .xl\:pl-15 {
    padding-left: 3.75rem; }
  .xl\:pr-15 {
    padding-right: 3.75rem; } }

@media (min-width: 1400px) {
  .xxl\:-mb-0 {
    margin-bottom: 0rem; }
  .xxl\:-mt-0 {
    margin-bottom: 0rem; }
  .xxl\:-mr-0 {
    margin-right: 0rem; }
  .xxl\:-ml-0 {
    margin-left: 0rem; }
  .xxl\:mb-0 {
    margin-bottom: 0rem; }
  .xxl\:mt-0 {
    margin-top: 0rem; }
  .xxl\:ml-0 {
    margin-left: 0rem; }
  .xxl\:mr-0 {
    margin-right: 0rem; }
  .xxl\:pb-0 {
    padding-bottom: 0rem; }
  .xxl\:pt-0 {
    padding-top: 0rem !important; }
  .xxl\:pl-0 {
    padding-left: 0rem; }
  .xxl\:pr-0 {
    padding-right: 0rem; }
  .xxl\:-mb-1 {
    margin-bottom: -0.25rem; }
  .xxl\:-mt-1 {
    margin-bottom: -0.25rem; }
  .xxl\:-mr-1 {
    margin-right: -0.25rem; }
  .xxl\:-ml-1 {
    margin-left: -0.25rem; }
  .xxl\:mb-1 {
    margin-bottom: 0.25rem; }
  .xxl\:mt-1 {
    margin-top: 0.25rem; }
  .xxl\:ml-1 {
    margin-left: 0.25rem; }
  .xxl\:mr-1 {
    margin-right: 0.25rem; }
  .xxl\:pb-1 {
    padding-bottom: 0.25rem; }
  .xxl\:pt-1 {
    padding-top: 0.25rem !important; }
  .xxl\:pl-1 {
    padding-left: 0.25rem; }
  .xxl\:pr-1 {
    padding-right: 0.25rem; }
  .xxl\:-mb-2 {
    margin-bottom: -0.5rem; }
  .xxl\:-mt-2 {
    margin-bottom: -0.5rem; }
  .xxl\:-mr-2 {
    margin-right: -0.5rem; }
  .xxl\:-ml-2 {
    margin-left: -0.5rem; }
  .xxl\:mb-2 {
    margin-bottom: 0.5rem; }
  .xxl\:mt-2 {
    margin-top: 0.5rem; }
  .xxl\:ml-2 {
    margin-left: 0.5rem; }
  .xxl\:mr-2 {
    margin-right: 0.5rem; }
  .xxl\:pb-2 {
    padding-bottom: 0.5rem; }
  .xxl\:pt-2 {
    padding-top: 0.5rem !important; }
  .xxl\:pl-2 {
    padding-left: 0.5rem; }
  .xxl\:pr-2 {
    padding-right: 0.5rem; }
  .xxl\:-mb-3 {
    margin-bottom: -0.75rem; }
  .xxl\:-mt-3 {
    margin-bottom: -0.75rem; }
  .xxl\:-mr-3 {
    margin-right: -0.75rem; }
  .xxl\:-ml-3 {
    margin-left: -0.75rem; }
  .xxl\:mb-3 {
    margin-bottom: 0.75rem; }
  .xxl\:mt-3 {
    margin-top: 0.75rem; }
  .xxl\:ml-3 {
    margin-left: 0.75rem; }
  .xxl\:mr-3 {
    margin-right: 0.75rem; }
  .xxl\:pb-3 {
    padding-bottom: 0.75rem; }
  .xxl\:pt-3 {
    padding-top: 0.75rem !important; }
  .xxl\:pl-3 {
    padding-left: 0.75rem; }
  .xxl\:pr-3 {
    padding-right: 0.75rem; }
  .xxl\:-mb-4 {
    margin-bottom: -1rem; }
  .xxl\:-mt-4 {
    margin-bottom: -1rem; }
  .xxl\:-mr-4 {
    margin-right: -1rem; }
  .xxl\:-ml-4 {
    margin-left: -1rem; }
  .xxl\:mb-4 {
    margin-bottom: 1rem; }
  .xxl\:mt-4 {
    margin-top: 1rem; }
  .xxl\:ml-4 {
    margin-left: 1rem; }
  .xxl\:mr-4 {
    margin-right: 1rem; }
  .xxl\:pb-4 {
    padding-bottom: 1rem; }
  .xxl\:pt-4 {
    padding-top: 1rem !important; }
  .xxl\:pl-4 {
    padding-left: 1rem; }
  .xxl\:pr-4 {
    padding-right: 1rem; }
  .xxl\:-mb-5 {
    margin-bottom: -1.25rem; }
  .xxl\:-mt-5 {
    margin-bottom: -1.25rem; }
  .xxl\:-mr-5 {
    margin-right: -1.25rem; }
  .xxl\:-ml-5 {
    margin-left: -1.25rem; }
  .xxl\:mb-5 {
    margin-bottom: 1.25rem; }
  .xxl\:mt-5 {
    margin-top: 1.25rem; }
  .xxl\:ml-5 {
    margin-left: 1.25rem; }
  .xxl\:mr-5 {
    margin-right: 1.25rem; }
  .xxl\:pb-5 {
    padding-bottom: 1.25rem; }
  .xxl\:pt-5 {
    padding-top: 1.25rem !important; }
  .xxl\:pl-5 {
    padding-left: 1.25rem; }
  .xxl\:pr-5 {
    padding-right: 1.25rem; }
  .xxl\:-mb-6 {
    margin-bottom: -1.5rem; }
  .xxl\:-mt-6 {
    margin-bottom: -1.5rem; }
  .xxl\:-mr-6 {
    margin-right: -1.5rem; }
  .xxl\:-ml-6 {
    margin-left: -1.5rem; }
  .xxl\:mb-6 {
    margin-bottom: 1.5rem; }
  .xxl\:mt-6 {
    margin-top: 1.5rem; }
  .xxl\:ml-6 {
    margin-left: 1.5rem; }
  .xxl\:mr-6 {
    margin-right: 1.5rem; }
  .xxl\:pb-6 {
    padding-bottom: 1.5rem; }
  .xxl\:pt-6 {
    padding-top: 1.5rem !important; }
  .xxl\:pl-6 {
    padding-left: 1.5rem; }
  .xxl\:pr-6 {
    padding-right: 1.5rem; }
  .xxl\:-mb-7 {
    margin-bottom: -1.75rem; }
  .xxl\:-mt-7 {
    margin-bottom: -1.75rem; }
  .xxl\:-mr-7 {
    margin-right: -1.75rem; }
  .xxl\:-ml-7 {
    margin-left: -1.75rem; }
  .xxl\:mb-7 {
    margin-bottom: 1.75rem; }
  .xxl\:mt-7 {
    margin-top: 1.75rem; }
  .xxl\:ml-7 {
    margin-left: 1.75rem; }
  .xxl\:mr-7 {
    margin-right: 1.75rem; }
  .xxl\:pb-7 {
    padding-bottom: 1.75rem; }
  .xxl\:pt-7 {
    padding-top: 1.75rem !important; }
  .xxl\:pl-7 {
    padding-left: 1.75rem; }
  .xxl\:pr-7 {
    padding-right: 1.75rem; }
  .xxl\:-mb-8 {
    margin-bottom: -2rem; }
  .xxl\:-mt-8 {
    margin-bottom: -2rem; }
  .xxl\:-mr-8 {
    margin-right: -2rem; }
  .xxl\:-ml-8 {
    margin-left: -2rem; }
  .xxl\:mb-8 {
    margin-bottom: 2rem; }
  .xxl\:mt-8 {
    margin-top: 2rem; }
  .xxl\:ml-8 {
    margin-left: 2rem; }
  .xxl\:mr-8 {
    margin-right: 2rem; }
  .xxl\:pb-8 {
    padding-bottom: 2rem; }
  .xxl\:pt-8 {
    padding-top: 2rem !important; }
  .xxl\:pl-8 {
    padding-left: 2rem; }
  .xxl\:pr-8 {
    padding-right: 2rem; }
  .xxl\:-mb-9 {
    margin-bottom: -2.25rem; }
  .xxl\:-mt-9 {
    margin-bottom: -2.25rem; }
  .xxl\:-mr-9 {
    margin-right: -2.25rem; }
  .xxl\:-ml-9 {
    margin-left: -2.25rem; }
  .xxl\:mb-9 {
    margin-bottom: 2.25rem; }
  .xxl\:mt-9 {
    margin-top: 2.25rem; }
  .xxl\:ml-9 {
    margin-left: 2.25rem; }
  .xxl\:mr-9 {
    margin-right: 2.25rem; }
  .xxl\:pb-9 {
    padding-bottom: 2.25rem; }
  .xxl\:pt-9 {
    padding-top: 2.25rem !important; }
  .xxl\:pl-9 {
    padding-left: 2.25rem; }
  .xxl\:pr-9 {
    padding-right: 2.25rem; }
  .xxl\:-mb-10 {
    margin-bottom: -2.5rem; }
  .xxl\:-mt-10 {
    margin-bottom: -2.5rem; }
  .xxl\:-mr-10 {
    margin-right: -2.5rem; }
  .xxl\:-ml-10 {
    margin-left: -2.5rem; }
  .xxl\:mb-10 {
    margin-bottom: 2.5rem; }
  .xxl\:mt-10 {
    margin-top: 2.5rem; }
  .xxl\:ml-10 {
    margin-left: 2.5rem; }
  .xxl\:mr-10 {
    margin-right: 2.5rem; }
  .xxl\:pb-10 {
    padding-bottom: 2.5rem; }
  .xxl\:pt-10 {
    padding-top: 2.5rem !important; }
  .xxl\:pl-10 {
    padding-left: 2.5rem; }
  .xxl\:pr-10 {
    padding-right: 2.5rem; }
  .xxl\:-mb-11 {
    margin-bottom: -2.75rem; }
  .xxl\:-mt-11 {
    margin-bottom: -2.75rem; }
  .xxl\:-mr-11 {
    margin-right: -2.75rem; }
  .xxl\:-ml-11 {
    margin-left: -2.75rem; }
  .xxl\:mb-11 {
    margin-bottom: 2.75rem; }
  .xxl\:mt-11 {
    margin-top: 2.75rem; }
  .xxl\:ml-11 {
    margin-left: 2.75rem; }
  .xxl\:mr-11 {
    margin-right: 2.75rem; }
  .xxl\:pb-11 {
    padding-bottom: 2.75rem; }
  .xxl\:pt-11 {
    padding-top: 2.75rem !important; }
  .xxl\:pl-11 {
    padding-left: 2.75rem; }
  .xxl\:pr-11 {
    padding-right: 2.75rem; }
  .xxl\:-mb-12 {
    margin-bottom: -3rem; }
  .xxl\:-mt-12 {
    margin-bottom: -3rem; }
  .xxl\:-mr-12 {
    margin-right: -3rem; }
  .xxl\:-ml-12 {
    margin-left: -3rem; }
  .xxl\:mb-12 {
    margin-bottom: 3rem; }
  .xxl\:mt-12 {
    margin-top: 3rem; }
  .xxl\:ml-12 {
    margin-left: 3rem; }
  .xxl\:mr-12 {
    margin-right: 3rem; }
  .xxl\:pb-12 {
    padding-bottom: 3rem; }
  .xxl\:pt-12 {
    padding-top: 3rem !important; }
  .xxl\:pl-12 {
    padding-left: 3rem; }
  .xxl\:pr-12 {
    padding-right: 3rem; }
  .xxl\:-mb-13 {
    margin-bottom: -3.25rem; }
  .xxl\:-mt-13 {
    margin-bottom: -3.25rem; }
  .xxl\:-mr-13 {
    margin-right: -3.25rem; }
  .xxl\:-ml-13 {
    margin-left: -3.25rem; }
  .xxl\:mb-13 {
    margin-bottom: 3.25rem; }
  .xxl\:mt-13 {
    margin-top: 3.25rem; }
  .xxl\:ml-13 {
    margin-left: 3.25rem; }
  .xxl\:mr-13 {
    margin-right: 3.25rem; }
  .xxl\:pb-13 {
    padding-bottom: 3.25rem; }
  .xxl\:pt-13 {
    padding-top: 3.25rem !important; }
  .xxl\:pl-13 {
    padding-left: 3.25rem; }
  .xxl\:pr-13 {
    padding-right: 3.25rem; }
  .xxl\:-mb-14 {
    margin-bottom: -3.5rem; }
  .xxl\:-mt-14 {
    margin-bottom: -3.5rem; }
  .xxl\:-mr-14 {
    margin-right: -3.5rem; }
  .xxl\:-ml-14 {
    margin-left: -3.5rem; }
  .xxl\:mb-14 {
    margin-bottom: 3.5rem; }
  .xxl\:mt-14 {
    margin-top: 3.5rem; }
  .xxl\:ml-14 {
    margin-left: 3.5rem; }
  .xxl\:mr-14 {
    margin-right: 3.5rem; }
  .xxl\:pb-14 {
    padding-bottom: 3.5rem; }
  .xxl\:pt-14 {
    padding-top: 3.5rem !important; }
  .xxl\:pl-14 {
    padding-left: 3.5rem; }
  .xxl\:pr-14 {
    padding-right: 3.5rem; }
  .xxl\:-mb-15 {
    margin-bottom: -3.75rem; }
  .xxl\:-mt-15 {
    margin-bottom: -3.75rem; }
  .xxl\:-mr-15 {
    margin-right: -3.75rem; }
  .xxl\:-ml-15 {
    margin-left: -3.75rem; }
  .xxl\:mb-15 {
    margin-bottom: 3.75rem; }
  .xxl\:mt-15 {
    margin-top: 3.75rem; }
  .xxl\:ml-15 {
    margin-left: 3.75rem; }
  .xxl\:mr-15 {
    margin-right: 3.75rem; }
  .xxl\:pb-15 {
    padding-bottom: 3.75rem; }
  .xxl\:pt-15 {
    padding-top: 3.75rem !important; }
  .xxl\:pl-15 {
    padding-left: 3.75rem; }
  .xxl\:pr-15 {
    padding-right: 3.75rem; } }

.-mb-16 {
  margin-bottom: -4-1rem; }

.-mb-20 {
  margin-bottom: -4-2rem; }

.-mb-24 {
  margin-bottom: -4-3rem; }

.-mb-28 {
  margin-bottom: -4-4rem; }

.-mb-32 {
  margin-bottom: -4-5rem; }

.-mb-36 {
  margin-bottom: -4-6rem; }

.-mb-40 {
  margin-bottom: -4-7rem; }

.-mb-44 {
  margin-bottom: -4-8rem; }

.-mb-48 {
  margin-bottom: -4-9rem; }

.-mb-52 {
  margin-bottom: -4-10rem; }

.-mb-56 {
  margin-bottom: -4-11rem; }

.-mb-60 {
  margin-bottom: -4-12rem; }

.-mb-64 {
  margin-bottom: -4-13rem; }

.-mt-16 {
  margin-top: -4-1rem; }

.-mt-20 {
  margin-top: -4-2rem; }

.-mt-24 {
  margin-top: -4-3rem; }

.-mt-28 {
  margin-top: -4-4rem; }

.-mt-32 {
  margin-top: -4-5rem; }

.-mt-36 {
  margin-top: -4-6rem; }

.-mt-40 {
  margin-top: -4-7rem; }

.-mt-44 {
  margin-top: -4-8rem; }

.-mt-48 {
  margin-top: -4-9rem; }

.-mt-52 {
  margin-top: -4-10rem; }

.-mt-56 {
  margin-top: -4-11rem; }

.-mt-60 {
  margin-top: -4-12rem; }

.-mt-64 {
  margin-top: -4-13rem; }

.-mr-16 {
  margin-right: -4-1rem; }

.-mr-20 {
  margin-right: -4-2rem; }

.-mr-24 {
  margin-right: -4-3rem; }

.-mr-28 {
  margin-right: -4-4rem; }

.-mr-32 {
  margin-right: -4-5rem; }

.-mr-36 {
  margin-right: -4-6rem; }

.-mr-40 {
  margin-right: -4-7rem; }

.-mr-44 {
  margin-right: -4-8rem; }

.-mr-48 {
  margin-right: -4-9rem; }

.-mr-52 {
  margin-right: -4-10rem; }

.-mr-56 {
  margin-right: -4-11rem; }

.-mr-60 {
  margin-right: -4-12rem; }

.-mr-64 {
  margin-right: -4-13rem; }

.-ml-16 {
  margin-left: -4-1rem; }

.-ml-20 {
  margin-left: -4-2rem; }

.-ml-24 {
  margin-left: -4-3rem; }

.-ml-28 {
  margin-left: -4-4rem; }

.-ml-32 {
  margin-left: -4-5rem; }

.-ml-36 {
  margin-left: -4-6rem; }

.-ml-40 {
  margin-left: -4-7rem; }

.-ml-44 {
  margin-left: -4-8rem; }

.-ml-48 {
  margin-left: -4-9rem; }

.-ml-52 {
  margin-left: -4-10rem; }

.-ml-56 {
  margin-left: -4-11rem; }

.-ml-60 {
  margin-left: -4-12rem; }

.-ml-64 {
  margin-left: -4-13rem; }

.mb-16 {
  margin-bottom: 5rem; }

.mb-20 {
  margin-bottom: 6rem; }

.mb-24 {
  margin-bottom: 7rem; }

.mb-28 {
  margin-bottom: 8rem; }

.mb-32 {
  margin-bottom: 9rem; }

.mb-36 {
  margin-bottom: 10rem; }

.mb-40 {
  margin-bottom: 11rem; }

.mb-44 {
  margin-bottom: 12rem; }

.mb-48 {
  margin-bottom: 13rem; }

.mb-52 {
  margin-bottom: 14rem; }

.mb-56 {
  margin-bottom: 15rem; }

.mb-60 {
  margin-bottom: 16rem; }

.mb-64 {
  margin-bottom: 17rem; }

.mt-16 {
  margin-top: 5rem; }

.mt-20 {
  margin-top: 6rem; }

.mt-24 {
  margin-top: 7rem; }

.mt-28 {
  margin-top: 8rem; }

.mt-32 {
  margin-top: 9rem; }

.mt-36 {
  margin-top: 10rem; }

.mt-40 {
  margin-top: 11rem; }

.mt-44 {
  margin-top: 12rem; }

.mt-48 {
  margin-top: 13rem; }

.mt-52 {
  margin-top: 14rem; }

.mt-56 {
  margin-top: 15rem; }

.mt-60 {
  margin-top: 16rem; }

.mt-64 {
  margin-top: 17rem; }

.mr-16 {
  margin-right: 5rem; }

.mr-20 {
  margin-right: 6rem; }

.mr-24 {
  margin-right: 7rem; }

.mr-28 {
  margin-right: 8rem; }

.mr-32 {
  margin-right: 9rem; }

.mr-36 {
  margin-right: 10rem; }

.mr-40 {
  margin-right: 11rem; }

.mr-44 {
  margin-right: 12rem; }

.mr-48 {
  margin-right: 13rem; }

.mr-52 {
  margin-right: 14rem; }

.mr-56 {
  margin-right: 15rem; }

.mr-60 {
  margin-right: 16rem; }

.mr-64 {
  margin-right: 17rem; }

.ml-16 {
  margin-left: 5rem; }

.ml-20 {
  margin-left: 6rem; }

.ml-24 {
  margin-left: 7rem; }

.ml-28 {
  margin-left: 8rem; }

.ml-32 {
  margin-left: 9rem; }

.ml-36 {
  margin-left: 10rem; }

.ml-40 {
  margin-left: 11rem; }

.ml-44 {
  margin-left: 12rem; }

.ml-48 {
  margin-left: 13rem; }

.ml-52 {
  margin-left: 14rem; }

.ml-56 {
  margin-left: 15rem; }

.ml-60 {
  margin-left: 16rem; }

.ml-64 {
  margin-left: 17rem; }

.pb-16 {
  padding-bottom: 5rem; }

.pb-20 {
  padding-bottom: 6rem; }

.pb-24 {
  padding-bottom: 7rem; }

.pb-28 {
  padding-bottom: 8rem; }

.pb-32 {
  padding-bottom: 9rem; }

.pb-36 {
  padding-bottom: 10rem; }

.pb-40 {
  padding-bottom: 11rem; }

.pb-44 {
  padding-bottom: 12rem; }

.pb-48 {
  padding-bottom: 13rem; }

.pb-52 {
  padding-bottom: 14rem; }

.pb-56 {
  padding-bottom: 15rem; }

.pb-60 {
  padding-bottom: 16rem; }

.pb-64 {
  padding-bottom: 17rem; }

.pt-16 {
  padding-top: 5rem; }

.pt-20 {
  padding-top: 6rem; }

.pt-24 {
  padding-top: 7rem; }

.pt-28 {
  padding-top: 8rem; }

.pt-32 {
  padding-top: 9rem; }

.pt-36 {
  padding-top: 10rem; }

.pt-40 {
  padding-top: 11rem; }

.pt-44 {
  padding-top: 12rem; }

.pt-48 {
  padding-top: 13rem; }

.pt-52 {
  padding-top: 14rem; }

.pt-56 {
  padding-top: 15rem; }

.pt-60 {
  padding-top: 16rem; }

.pt-64 {
  padding-top: 17rem; }

.pr-16 {
  padding-right: 5rem; }

.pr-20 {
  padding-right: 6rem; }

.pr-24 {
  padding-right: 7rem; }

.pr-28 {
  padding-right: 8rem; }

.pr-32 {
  padding-right: 9rem; }

.pr-36 {
  padding-right: 10rem; }

.pr-40 {
  padding-right: 11rem; }

.pr-44 {
  padding-right: 12rem; }

.pr-48 {
  padding-right: 13rem; }

.pr-52 {
  padding-right: 14rem; }

.pr-56 {
  padding-right: 15rem; }

.pr-60 {
  padding-right: 16rem; }

.pr-64 {
  padding-right: 17rem; }

.pl-16 {
  padding-left: 5rem; }

.pl-20 {
  padding-left: 6rem; }

.pl-24 {
  padding-left: 7rem; }

.pl-28 {
  padding-left: 8rem; }

.pl-32 {
  padding-left: 9rem; }

.pl-36 {
  padding-left: 10rem; }

.pl-40 {
  padding-left: 11rem; }

.pl-44 {
  padding-left: 12rem; }

.pl-48 {
  padding-left: 13rem; }

.pl-52 {
  padding-left: 14rem; }

.pl-56 {
  padding-left: 15rem; }

.pl-60 {
  padding-left: 16rem; }

.pl-64 {
  padding-left: 17rem; }

@media (min-width: 1601px) {
  .xxl\:pl-16 {
    padding-left: 5rem; }
  .xxl\:pl-20 {
    padding-left: 6rem; }
  .xxl\:pl-24 {
    padding-left: 7rem; }
  .xxl\:pl-28 {
    padding-left: 8rem; }
  .xxl\:pl-32 {
    padding-left: 9rem; }
  .xxl\:pl-36 {
    padding-left: 10rem; }
  .xxl\:pl-40 {
    padding-left: 11rem; }
  .xxl\:pl-44 {
    padding-left: 12rem; }
  .xxl\:pl-48 {
    padding-left: 13rem; }
  .xxl\:pl-52 {
    padding-left: 14rem; }
  .xxl\:pl-56 {
    padding-left: 15rem; }
  .xxl\:pl-60 {
    padding-left: 16rem; }
  .xxl\:pl-64 {
    padding-left: 17rem; } }

.grid {
  display: grid; }

.gap-0 {
  gap: 0rem; }

.gap-1 {
  gap: 0.25rem; }

.gap-2 {
  gap: 0.5rem; }

.gap-3 {
  gap: 0.75rem; }

.gap-4 {
  gap: 1rem; }

.gap-5 {
  gap: 1.25rem; }

.gap-6 {
  gap: 1.5rem; }

.gap-7 {
  gap: 1.75rem; }

.gap-8 {
  gap: 2rem; }

.gap-9 {
  gap: 2.25rem; }

.gap-10 {
  gap: 2.5rem; }

.gap-11 {
  gap: 2.75rem; }

.gap-12 {
  gap: 3rem; }

.gap-13 {
  gap: 3.25rem; }

.gap-14 {
  gap: 3.5rem; }

.gap-15 {
  gap: 3.75rem; }

.gap-16 {
  gap: 5rem; }

.gap-20 {
  gap: 6rem; }

.gap-24 {
  gap: 7rem; }

.gap-28 {
  gap: 8rem; }

.gap-32 {
  gap: 9rem; }

.gap-36 {
  gap: 10rem; }

.gap-40 {
  gap: 11rem; }

.gap-44 {
  gap: 12rem; }

.gap-48 {
  gap: 13rem; }

.gap-52 {
  gap: 14rem; }

.gap-56 {
  gap: 15rem; }

.gap-60 {
  gap: 16rem; }

.gap-64 {
  gap: 17rem; }

.grid-flow-row {
  grid-auto-flow: row; }

.grid-flow-col {
  grid-auto-flow: column; }

.grid-flow-row-dense {
  grid-auto-flow: row dense; }

.grid-flow-col-dense {
  grid-auto-flow: column dense; }

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)); }

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)); }

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)); }

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)); }

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)); }

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)); }

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)); }

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)); }

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)); }

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)); }

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)); }

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)); }

.grid-cols-none {
  grid-template-columns: none; }

.auto-cols-auto {
  grid-auto-columns: auto; }

.auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content; }

.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content; }

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr); }

.col-auto {
  grid-column: auto; }

.col-span-1 {
  grid-column: span 1/span 1; }

.col-span-2 {
  grid-column: span 2/span 2; }

.col-span-3 {
  grid-column: span 3/span 3; }

.col-span-4 {
  grid-column: span 4/span 4; }

.col-span-5 {
  grid-column: span 5/span 5; }

.col-span-6 {
  grid-column: span 6/span 6; }

.col-span-7 {
  grid-column: span 7/span 7; }

.col-span-8 {
  grid-column: span 8/span 8; }

.col-span-9 {
  grid-column: span 9/span 9; }

.col-span-10 {
  grid-column: span 10/span 10; }

.col-span-11 {
  grid-column: span 11/span 11; }

.col-span-12 {
  grid-column: span 12/span 12; }

@media (min-width: 576px) {
  .sm\:col-span-1 {
    grid-column: span 1/span 1; }
  .sm\:col-span-2 {
    grid-column: span 2/span 2; }
  .sm\:col-span-3 {
    grid-column: span 3/span 3; }
  .sm\:col-span-4 {
    grid-column: span 4/span 4; }
  .sm\:col-span-5 {
    grid-column: span 5/span 5; }
  .sm\:col-span-6 {
    grid-column: span 6/span 6; }
  .sm\:col-span-7 {
    grid-column: span 7/span 7; }
  .sm\:col-span-8 {
    grid-column: span 8/span 8; }
  .sm\:col-span-9 {
    grid-column: span 9/span 9; }
  .sm\:col-span-10 {
    grid-column: span 10/span 10; }
  .sm\:col-span-11 {
    grid-column: span 11/span 11; }
  .sm\:col-span-12 {
    grid-column: span 12/span 12; } }

@media (min-width: 768px) {
  .md\:col-span-1 {
    grid-column: span 1/span 1; }
  .md\:col-span-2 {
    grid-column: span 2/span 2; }
  .md\:col-span-3 {
    grid-column: span 3/span 3; }
  .md\:col-span-4 {
    grid-column: span 4/span 4; }
  .md\:col-span-5 {
    grid-column: span 5/span 5; }
  .md\:col-span-6 {
    grid-column: span 6/span 6; }
  .md\:col-span-7 {
    grid-column: span 7/span 7; }
  .md\:col-span-8 {
    grid-column: span 8/span 8; }
  .md\:col-span-9 {
    grid-column: span 9/span 9; }
  .md\:col-span-10 {
    grid-column: span 10/span 10; }
  .md\:col-span-11 {
    grid-column: span 11/span 11; }
  .md\:col-span-12 {
    grid-column: span 12/span 12; } }

@media (min-width: 992px) {
  .lg\:col-span-1 {
    grid-column: span 1/span 1; }
  .lg\:col-span-2 {
    grid-column: span 2/span 2; }
  .lg\:col-span-3 {
    grid-column: span 3/span 3; }
  .lg\:col-span-4 {
    grid-column: span 4/span 4; }
  .lg\:col-span-5 {
    grid-column: span 5/span 5; }
  .lg\:col-span-6 {
    grid-column: span 6/span 6; }
  .lg\:col-span-7 {
    grid-column: span 7/span 7; }
  .lg\:col-span-8 {
    grid-column: span 8/span 8; }
  .lg\:col-span-9 {
    grid-column: span 9/span 9; }
  .lg\:col-span-10 {
    grid-column: span 10/span 10; }
  .lg\:col-span-11 {
    grid-column: span 11/span 11; }
  .lg\:col-span-12 {
    grid-column: span 12/span 12; } }

@media (min-width: 1200px) {
  .xl\:col-span-1 {
    grid-column: span 1/span 1; }
  .xl\:col-span-2 {
    grid-column: span 2/span 2; }
  .xl\:col-span-3 {
    grid-column: span 3/span 3; }
  .xl\:col-span-4 {
    grid-column: span 4/span 4; }
  .xl\:col-span-5 {
    grid-column: span 5/span 5; }
  .xl\:col-span-6 {
    grid-column: span 6/span 6; }
  .xl\:col-span-7 {
    grid-column: span 7/span 7; }
  .xl\:col-span-8 {
    grid-column: span 8/span 8; }
  .xl\:col-span-9 {
    grid-column: span 9/span 9; }
  .xl\:col-span-10 {
    grid-column: span 10/span 10; }
  .xl\:col-span-11 {
    grid-column: span 11/span 11; }
  .xl\:col-span-12 {
    grid-column: span 12/span 12; } }

@media (min-width: 1601px) {
  .xxl\:col-span-1 {
    grid-column: span 1/span 1; }
  .xxl\:col-span-2 {
    grid-column: span 2/span 2; }
  .xxl\:col-span-3 {
    grid-column: span 3/span 3; }
  .xxl\:col-span-4 {
    grid-column: span 4/span 4; }
  .xxl\:col-span-5 {
    grid-column: span 5/span 5; }
  .xxl\:col-span-6 {
    grid-column: span 6/span 6; }
  .xxl\:col-span-7 {
    grid-column: span 7/span 7; }
  .xxl\:col-span-8 {
    grid-column: span 8/span 8; }
  .xxl\:col-span-9 {
    grid-column: span 9/span 9; }
  .xxl\:col-span-10 {
    grid-column: span 10/span 10; }
  .xxl\:col-span-11 {
    grid-column: span 11/span 11; }
  .xxl\:col-span-12 {
    grid-column: span 12/span 12; } }

.col-span-full {
  grid-column: 1 / -1; }

.col-start-1 {
  grid-column-start: 1; }

.col-start-2 {
  grid-column-start: 2; }

.col-start-3 {
  grid-column-start: 3; }

.col-start-4 {
  grid-column-start: 4; }

.col-start-5 {
  grid-column-start: 5; }

.col-start-6 {
  grid-column-start: 6; }

.col-start-7 {
  grid-column-start: 7; }

.col-start-8 {
  grid-column-start: 8; }

.col-start-9 {
  grid-column-start: 9; }

.col-start-10 {
  grid-column-start: 10; }

.col-start-11 {
  grid-column-start: 11; }

.col-start-12 {
  grid-column-start: 12; }

.col-start-13 {
  grid-column-start: 13; }

@media (min-width: 576px) {
  .sm\:col-start-1 {
    grid-column-start: 1; }
  .sm\:col-start-2 {
    grid-column-start: 2; }
  .sm\:col-start-3 {
    grid-column-start: 3; }
  .sm\:col-start-4 {
    grid-column-start: 4; }
  .sm\:col-start-5 {
    grid-column-start: 5; }
  .sm\:col-start-6 {
    grid-column-start: 6; }
  .sm\:col-start-7 {
    grid-column-start: 7; }
  .sm\:col-start-8 {
    grid-column-start: 8; }
  .sm\:col-start-9 {
    grid-column-start: 9; }
  .sm\:col-start-10 {
    grid-column-start: 10; }
  .sm\:col-start-11 {
    grid-column-start: 11; }
  .sm\:col-start-12 {
    grid-column-start: 12; }
  .sm\:col-start-13 {
    grid-column-start: 13; }
  .sm\:col-start-auto {
    grid-column-start: auto; } }

@media (min-width: 768px) {
  .md\:col-start-1 {
    grid-column-start: 1; }
  .md\:col-start-2 {
    grid-column-start: 2; }
  .md\:col-start-3 {
    grid-column-start: 3; }
  .md\:col-start-4 {
    grid-column-start: 4; }
  .md\:col-start-5 {
    grid-column-start: 5; }
  .md\:col-start-6 {
    grid-column-start: 6; }
  .md\:col-start-7 {
    grid-column-start: 7; }
  .md\:col-start-8 {
    grid-column-start: 8; }
  .md\:col-start-9 {
    grid-column-start: 9; }
  .md\:col-start-10 {
    grid-column-start: 10; }
  .md\:col-start-11 {
    grid-column-start: 11; }
  .md\:col-start-12 {
    grid-column-start: 12; }
  .md\:col-start-13 {
    grid-column-start: 13; }
  .md\:col-start-auto {
    grid-column-start: auto; } }

@media (min-width: 992px) {
  .lg\:col-start-1 {
    grid-column-start: 1; }
  .lg\:col-start-2 {
    grid-column-start: 2; }
  .lg\:col-start-3 {
    grid-column-start: 3; }
  .lg\:col-start-4 {
    grid-column-start: 4; }
  .lg\:col-start-5 {
    grid-column-start: 5; }
  .lg\:col-start-6 {
    grid-column-start: 6; }
  .lg\:col-start-7 {
    grid-column-start: 7; }
  .lg\:col-start-8 {
    grid-column-start: 8; }
  .lg\:col-start-9 {
    grid-column-start: 9; }
  .lg\:col-start-10 {
    grid-column-start: 10; }
  .lg\:col-start-11 {
    grid-column-start: 11; }
  .lg\:col-start-12 {
    grid-column-start: 12; }
  .lg\:col-start-13 {
    grid-column-start: 13; }
  .lg\:col-start-auto {
    grid-column-start: auto; } }

@media (min-width: 1200px) {
  .xl\:col-start-1 {
    grid-column-start: 1; }
  .xl\:col-start-2 {
    grid-column-start: 2; }
  .xl\:col-start-3 {
    grid-column-start: 3; }
  .xl\:col-start-4 {
    grid-column-start: 4; }
  .xl\:col-start-5 {
    grid-column-start: 5; }
  .xl\:col-start-6 {
    grid-column-start: 6; }
  .xl\:col-start-7 {
    grid-column-start: 7; }
  .xl\:col-start-8 {
    grid-column-start: 8; }
  .xl\:col-start-9 {
    grid-column-start: 9; }
  .xl\:col-start-10 {
    grid-column-start: 10; }
  .xl\:col-start-11 {
    grid-column-start: 11; }
  .xl\:col-start-12 {
    grid-column-start: 12; }
  .xl\:col-start-13 {
    grid-column-start: 13; }
  .xl\:col-start-auto {
    grid-column-start: auto; } }

@media (min-width: 1601px) {
  .xxl\:col-start-1 {
    grid-column-start: 1; }
  .xxl\:col-start-2 {
    grid-column-start: 2; }
  .xxl\:col-start-3 {
    grid-column-start: 3; }
  .xxl\:col-start-4 {
    grid-column-start: 4; }
  .xxl\:col-start-5 {
    grid-column-start: 5; }
  .xxl\:col-start-6 {
    grid-column-start: 6; }
  .xxl\:col-start-7 {
    grid-column-start: 7; }
  .xxl\:col-start-8 {
    grid-column-start: 8; }
  .xxl\:col-start-9 {
    grid-column-start: 9; }
  .xxl\:col-start-10 {
    grid-column-start: 10; }
  .xxl\:col-start-11 {
    grid-column-start: 11; }
  .xxl\:col-start-12 {
    grid-column-start: 12; }
  .xxl\:col-start-13 {
    grid-column-start: 13; }
  .xxl\:col-start-auto {
    grid-column-start: auto; } }

.col-start-auto {
  grid-column-start: auto; }

.col-end-1 {
  grid-column-end: 1; }

.col-end-2 {
  grid-column-end: 2; }

.col-end-3 {
  grid-column-end: 3; }

.col-end-4 {
  grid-column-end: 4; }

.col-end-5 {
  grid-column-end: 5; }

.col-end-6 {
  grid-column-end: 6; }

.col-end-7 {
  grid-column-end: 7; }

.col-end-8 {
  grid-column-end: 8; }

.col-end-9 {
  grid-column-end: 9; }

.col-end-10 {
  grid-column-end: 10; }

.col-end-11 {
  grid-column-end: 11; }

.col-end-12 {
  grid-column-end: 12; }

.col-end-13 {
  grid-column-end: 13; }

.col-end-auto {
  grid-column-end: auto; }

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)); }

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)); }

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)); }

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)); }

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)); }

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)); }

.grid-rows-none {
  grid-template-rows: none; }

.auto-rows-auto {
  grid-auto-rows: auto; }

.auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content; }

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content; }

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr); }

.row-auto {
  grid-row: auto; }

.row-span-1 {
  grid-row: span 1/span 1; }

.row-span-2 {
  grid-row: span 2/span 2; }

.row-span-3 {
  grid-row: span 3/span 3; }

.row-span-4 {
  grid-row: span 4/span 4; }

.row-span-5 {
  grid-row: span 5/span 5; }

.row-span-6 {
  grid-row: span 6/span 6; }

.row-span-full {
  grid-row: 1 / -1; }

.row-start-1 {
  grid-row-start: 1; }

.row-start-2 {
  grid-row-start: 2; }

.row-start-3 {
  grid-row-start: 3; }

.row-start-4 {
  grid-row-start: 4; }

.row-start-5 {
  grid-row-start: 5; }

.row-start-6 {
  grid-row-start: 6; }

.row-start-7 {
  grid-row-start: 7; }

@media (min-width: 576px) {
  .sm\:row-start-1 {
    grid-row-start: 1; }
  .sm\:row-start-2 {
    grid-row-start: 2; }
  .sm\:row-start-3 {
    grid-row-start: 3; }
  .sm\:row-start-4 {
    grid-row-start: 4; }
  .sm\:row-start-5 {
    grid-row-start: 5; }
  .sm\:row-start-6 {
    grid-row-start: 6; }
  .sm\:row-start-7 {
    grid-row-start: 7; }
  .sm\:row-start-auto {
    grid-row-start: auto; } }

@media (min-width: 768px) {
  .md\:row-start-1 {
    grid-row-start: 1; }
  .md\:row-start-2 {
    grid-row-start: 2; }
  .md\:row-start-3 {
    grid-row-start: 3; }
  .md\:row-start-4 {
    grid-row-start: 4; }
  .md\:row-start-5 {
    grid-row-start: 5; }
  .md\:row-start-6 {
    grid-row-start: 6; }
  .md\:row-start-7 {
    grid-row-start: 7; }
  .md\:row-start-auto {
    grid-row-start: auto; } }

@media (min-width: 992px) {
  .lg\:row-start-1 {
    grid-row-start: 1; }
  .lg\:row-start-2 {
    grid-row-start: 2; }
  .lg\:row-start-3 {
    grid-row-start: 3; }
  .lg\:row-start-4 {
    grid-row-start: 4; }
  .lg\:row-start-5 {
    grid-row-start: 5; }
  .lg\:row-start-6 {
    grid-row-start: 6; }
  .lg\:row-start-7 {
    grid-row-start: 7; }
  .lg\:row-start-auto {
    grid-row-start: auto; } }

@media (min-width: 1200px) {
  .xl\:row-start-1 {
    grid-row-start: 1; }
  .xl\:row-start-2 {
    grid-row-start: 2; }
  .xl\:row-start-3 {
    grid-row-start: 3; }
  .xl\:row-start-4 {
    grid-row-start: 4; }
  .xl\:row-start-5 {
    grid-row-start: 5; }
  .xl\:row-start-6 {
    grid-row-start: 6; }
  .xl\:row-start-7 {
    grid-row-start: 7; }
  .xl\:row-start-auto {
    grid-row-start: auto; } }

@media (min-width: 1601px) {
  .xxl\:row-start-1 {
    grid-row-start: 1; }
  .xxl\:row-start-2 {
    grid-row-start: 2; }
  .xxl\:row-start-3 {
    grid-row-start: 3; }
  .xxl\:row-start-4 {
    grid-row-start: 4; }
  .xxl\:row-start-5 {
    grid-row-start: 5; }
  .xxl\:row-start-6 {
    grid-row-start: 6; }
  .xxl\:row-start-7 {
    grid-row-start: 7; }
  .xxl\:row-start-auto {
    grid-row-start: auto; } }

.row-start-auto {
  grid-row-start: auto; }

.row-end-1 {
  grid-row-end: 1; }

.row-end-2 {
  grid-row-end: 2; }

.row-end-3 {
  grid-row-end: 3; }

.row-end-4 {
  grid-row-end: 4; }

.row-end-5 {
  grid-row-end: 5; }

.row-end-6 {
  grid-row-end: 6; }

.row-end-7 {
  grid-row-end: 7; }

.row-end-auto {
  grid-row-end: auto; }

.inset-y-0 {
  top: 0px;
  bottom: 0px; }

.inset-x-0 {
  right: 0px;
  left: 0px; }

.file__icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: relative; }

.file__icon--directory {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A')'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='%23linear-gradient'/%3E%3C/g%3E%3C/svg%3E%0A)"); }

.min-h-screen {
  min-height: 100vh; }

.h-screen {
  height: 100vh; }

@media (min-width: 575px) {
  .w-sm-75 {
    width: 75% !important; } }

@media (min-width: 1024px) {
  .w-lg-50 {
    width: 50% !important; } }

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important; } }

.table {
  text-align: left;
  width: 100%; }
  .table th {
    font-weight: 500;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: none; }
  .table td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    vertical-align: middle; }
  .table.table--sm th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem; }
  .table.table--sm td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem; }

.table-report:not(.table-report--bordered):not(.table-report--tabulator) {
  border-spacing: 0 10px;
  border-collapse: separate; }
  .table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
    border-bottom: none;
    background-color: #FFF;
    box-shadow: 20px 3px 20px rgba(0, 0, 0, 0.04); }
    .table-report:not(.table-report--bordered):not(.table-report--tabulator) td:first-child {
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem; }
    .table-report:not(.table-report--bordered):not(.table-report--tabulator) td:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem; }
    .table-report:not(.table-report--bordered):not(.table-report--tabulator) td.table-report__action {
      padding-top: 0px;
      padding-bottom: 0px;
      position: relative; }
      .table-report:not(.table-report--bordered):not(.table-report--tabulator) td.table-report__action:before {
        content: "";
        background-color: #e5e7eb;
        height: 2rem;
        margin-top: auto;
        margin-bottom: auto;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        width: 1px; }

.table-report img {
  box-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, 0.32); }

.table-report.table-report--tabulator .tabulator-header .tabulator-headers .tabulator-col:nth-child(3) .tabulator-col-content .tabulator-col-title, .table-report.table-report--tabulator .tabulator-header .tabulator-headers .tabulator-col:nth-child(4) .tabulator-col-content .tabulator-col-title, .table-report.table-report--tabulator .tabulator-header .tabulator-headers .tabulator-col:nth-child(5) .tabulator-col-content .tabulator-col-title, .table-report.table-report--tabulator .tabulator-header .tabulator-headers .tabulator-col:nth-child(6) .tabulator-col-content .tabulator-col-title {
  text-align: center; }

.pagination {
  display: flex;
  margin-right: auto; }
  @media (max-width: 575px) {
    .pagination {
      margin-right: 0px;
      width: 100%; } }
  @media (max-width: 575px) {
    .pagination li {
      flex: 1 1 0%; }
      .pagination li:nth-child(1) .pagination__link, .pagination li:nth-child(2) .pagination__link, .pagination li:nth-child(3) .pagination__link, .pagination li:nth-child(7) .pagination__link, .pagination li:nth-child(8) .pagination__link, .pagination li:nth-child(9) .pagination__link {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
  .pagination li .pagination__link {
    min-width: 40px;
    border-radius: 0.375rem;
    cursor: pointer;
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    margin-right: 0.5rem;
    color: #1f2937; }
    @media (max-width: 575px) {
      .pagination li .pagination__link {
        margin-right: 0px;
        min-width: 0px; } }
    .pagination li .pagination__link:focus {
      outline: 2px solid transparent;
      outline-offset: 2px; }
    .pagination li .pagination__link:not(button) {
      text-align: center; }
    .pagination li .pagination__link.button--sm {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .pagination li .pagination__link.button--lg {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem; }
    .pagination li .pagination__link.pagination__link--active {
      box-shadow: 0px 3px 20px #0000000b;
      background-color: white;
      border-radius: 0.375rem;
      position: relative; }

.report-timeline:before {
  content: "";
  height: 85%;
  background-color: #dee7ef;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  position: absolute;
  width: 1px; }

.report-timeline .report-timeline__image:before {
  content: "";
  background-color: #dee7ef;
  height: 1px;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  position: absolute;
  width: 5rem; }

.select2-container {
  margin: 0px;
  vertical-align: top;
  padding: 0;
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  font-family: inherit; }
  .select2-container mark {
    background-color: #d32929;
    border-radius: 0.25rem; }
  .select2-container:hover .select-label,
  .select2-container.idle .select-label,
  .select2-container.active .select-label {
    box-shadow: none; }
  .select2-container .select2-selection {
    height: auto;
    border-color: #d1d5db; }
    .select2-container .select2-selection:focus {
      outline: none; }
    .select2-container .select2-selection__rendered {
      border-radius: 0.375rem;
      border-width: 0px;
      box-shadow: none;
      color: #1f2937;
      cursor: pointer;
      width: 100%;
      margin: 0;
      padding: 5px 30px 5px 0.75rem;
      display: block;
      z-index: 27;
      position: relative;
      text-align: left;
      border-style: solid; }
    .select2-container .select2-selection__arrow {
      z-index: 1000 !important;
      height: 39px !important; }
  .select2-container .select2-dropdown {
    border-color: #d1d5db;
    border-radius: 0.25rem;
    border-width: 1px;
    box-shadow: none; }
    .select2-container .select2-dropdown .select2-search {
      border-color: #d1d5db;
      padding: 0.25rem;
      box-shadow: none; }
      .select2-container .select2-dropdown .select2-search input[type="search"] {
        padding: 8px 10px;
        border-color: #d1d5db;
        border-radius: 0.25rem;
        border-width: 1px;
        font-size: 0.875rem;
        line-height: 1.25rem; }
        .select2-container .select2-dropdown .select2-search input[type="search"]:focus {
          border-color: #d1d5db;
          border-radius: 0.25rem;
          border-width: 1px;
          color: #1f2937; }
    .select2-container .select2-dropdown .select2-results__options {
      width: 100%;
      margin: 0;
      padding: 0;
      display: block;
      position: relative;
      list-style: none;
      font-size: 14px;
      line-height: 20px;
      vertical-align: top; }
    .select2-container .select2-dropdown .select2-results__option {
      padding: 9px 13px;
      font-size: 0.875rem;
      line-height: 1.25rem; }
      .select2-container .select2-dropdown .select2-results__option:first-of-type {
        margin-top: 0px; }
        .select2-container .select2-dropdown .select2-results__option:first-of-type--selected {
          border-color: #d1d5db;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          border-top-width: 1px; }
      .select2-container .select2-dropdown .select2-results__option:last-of-type {
        margin-bottom: 0px; }
        .select2-container .select2-dropdown .select2-results__option:last-of-type--selected {
          border-color: #d1d5db;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-bottom-width: 1px; }
      .select2-container .select2-dropdown .select2-results__option--highlighted {
        background-color: #dfe3e7;
        color: #1f2937; }
      .select2-container .select2-dropdown .select2-results__option--selected {
        background-color: #4a9bec;
        color: #FFF; }
        .select2-container .select2-dropdown .select2-results__option--selected--highlighted {
          background-color: #4a9bec;
          color: #FFF; }
    .select2-container .select2-dropdown .select2-results__option--group {
      padding: 0px; }

.toastify {
  padding: 12px 20px;
  color: #ffffff;
  display: inline-block;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
  background: -webkit-linear-gradient(315deg, #73a5ff, #5477f5);
  background: linear-gradient(135deg, #73a5ff, #5477f5);
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647; }
  .toastify.on {
    opacity: 1; }
  .toastify .toast-close {
    opacity: 0.4;
    padding: 0 5px; }
  .toastify.toastify-right {
    right: 15px; }
    @media only screen and (max-width: 375px) {
      .toastify.toastify-right {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content; } }
  .toastify.toastify-left {
    left: 15px; }
    @media only screen and (max-width: 375px) {
      .toastify.toastify-left {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content; } }
  .toastify.toastify-top {
    top: -150px; }
  .toastify.toastify-bottom {
    bottom: -150px; }
  .toastify.toastify-rounded {
    border-radius: 25px; }
  .toastify .toastify-avatar {
    width: 1.5em;
    height: 1.5em;
    margin: -7px 5px;
    border-radius: 2px; }
  .toastify.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
    max-width: -moz-fit-content; }

.dataTables_wrapper .custom-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74, 85, 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-size: 15px;
  background-position: center right 0.6rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  border-radius: 0.375rem;
  margin-left: 0.5rem;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2rem;
  border: 1px solid #d1d5db; }

.dataTables_wrapper .form-control {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.02);
  opacity: 1;
  background-color: white;
  border-radius: 0.375rem;
  position: relative;
  border: none !important; }

.dataTables_wrapper .data-table {
  width: 100% !important; }

@media screen and (max-width: 575px) {
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 10px; } }

@media screen and (max-width: 575px) {
  .dataTables_wrapper .dataTables_info {
    margin-bottom: 10px; } }

.dataTables_wrapper .pagination {
  flex-wrap: wrap; }
  .dataTables_wrapper .pagination li {
    flex: 0; }
    .dataTables_wrapper .pagination li .page-link {
      min-width: 40px;
      border-radius: 0.375rem;
      cursor: pointer;
      font-weight: 400;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      margin: 0 2px;
      border: none;
      background-color: transparent;
      text-align: center;
      color: #1f2937; }
      .dataTables_wrapper .pagination li .page-link:focus {
        box-shadow: none; }
    .dataTables_wrapper .pagination li.previous .page-link, .dataTables_wrapper .pagination li.next .page-link {
      background-color: #e5e7eb; }
    .dataTables_wrapper .pagination li:hover .page-link, .dataTables_wrapper .pagination li.active .page-link {
      font-weight: 500;
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.02);
      background-color: white;
      position: relative; }
    .dataTables_wrapper .pagination li.disabled {
      cursor: not-allowed; }

.accordion .accordion-header .accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .accordion .accordion-header .accordion-button:not(.collapsed) {
    background-color: #4a9bec;
    color: #FFF; }
    .accordion .accordion-header .accordion-button:not(.collapsed) svg {
      transform: rotate(180deg);
      fill: #FFF; }
  .accordion .accordion-header .accordion-button::after {
    display: none; }
  .accordion .accordion-header .accordion-button svg {
    fill: #1f2937;
    transition: 0.3s ease-in-out; }
  .accordion .accordion-header .accordion-button:focus {
    box-shadow: none; }

.accordion.accordion-pills .accordion-item {
  margin-bottom: 10px; }
  .accordion.accordion-pills .accordion-item .accordion-button {
    background-color: #4a9bec;
    color: #FFF;
    border: none;
    border-radius: 0.375rem !important; }
    .accordion.accordion-pills .accordion-item .accordion-button svg {
      fill: #FFF; }
  .accordion.accordion-pills .accordion-item .accordion-collapse {
    border: none; }

.alert.alert-primary {
  background-color: #4a9bec;
  border-color: #1c82e7;
  color: #FFF; }
  .alert.alert-primary a {
    color: inherit; }

.alert button:focus {
  box-shadow: none; }

.nav-tabs {
  border: none; }
  .nav-tabs .nav-link {
    border-width: 0;
    border-bottom-width: 2px;
    border-color: transparent;
    background-color: transparent; }
    .nav-tabs .nav-link.active {
      border-color: #4a9bec; }
    .nav-tabs .nav-link:focus {
      box-shadow: none;
      outline: none; }

.dropzone {
  min-height: 150px;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center; }
  .dropzone.dz-clickable {
    cursor: pointer; }
    .dropzone.dz-clickable * {
      cursor: default; }
    .dropzone.dz-clickable .dz-message {
      cursor: pointer; }
      .dropzone.dz-clickable .dz-message * {
        cursor: pointer; }
  .dropzone.dz-started .dz-message {
    display: none; }
  .dropzone.dz-drag-hover {
    border-style: solid; }
    .dropzone.dz-drag-hover .dz-message {
      opacity: 0.5; }
  .dropzone .dz-message {
    text-align: center;
    margin: 2em 0; }
    .dropzone .dz-message .dz-button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit; }
  .dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px; }
    .dropzone .dz-preview:hover {
      z-index: 1000; }
      .dropzone .dz-preview:hover .dz-details {
        opacity: 1; }
      .dropzone .dz-preview:hover .dz-image img {
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        -webkit-filter: blur(8px);
        filter: blur(8px); }
    .dropzone .dz-preview.dz-file-preview .dz-image {
      border-radius: 20px;
      background: #999;
      background: linear-gradient(to bottom, #eee, #ddd); }
    .dropzone .dz-preview.dz-file-preview .dz-details {
      -webkit-transition: opacity 0.2s linear;
      -moz-transition: opacity 0.2s linear;
      -ms-transition: opacity 0.2s linear;
      -o-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear; }
    .dropzone .dz-preview .dz-image {
      border-radius: 20px;
      overflow: hidden;
      width: 120px;
      height: 120px;
      position: relative;
      display: block;
      z-index: 10; }
      .dropzone .dz-preview .dz-image img {
        display: block; }
    .dropzone .dz-preview .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none; }
      .dropzone .dz-preview .dz-remove:hover {
        text-decoration: underline; }
        .dropzone .dz-preview .dz-remove:hover .dz-details {
          opacity: 1; }
    .dropzone .dz-preview .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%; }
      .dropzone .dz-preview .dz-details .dz-size {
        margin-bottom: 1em;
        font-size: 16px; }
        .dropzone .dz-preview .dz-details .dz-size span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px; }
      .dropzone .dz-preview .dz-details .dz-filename {
        white-space: nowrap; }
        .dropzone .dz-preview .dz-details .dz-filename:hover span {
          border: 1px solid rgba(200, 200, 200, 0.8);
          background-color: rgba(255, 255, 255, 0.8); }
        .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis; }
          .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
            border: 1px solid transparent; }
        .dropzone .dz-preview .dz-details .dz-filename span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px; }
    .dropzone .dz-preview.dz-success .dz-success-mark, .dropzone .dz-preview.dz-success .dz-error-mark {
      -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview.dz-error .dz-error-mark {
      opacity: 1;
      -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px; }
      .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
        display: block;
        width: 54px;
        height: 54px; }
    .dropzone .dz-preview .dz-error-mark svg {
      display: block;
      width: 54px;
      height: 54px; }
    .dropzone .dz-preview.dz-processing .dz-progress {
      opacity: 1;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear; }
    .dropzone .dz-preview.dz-complete .dz-progress {
      opacity: 0;
      -webkit-transition: opacity 0.4s ease-in;
      -moz-transition: opacity 0.4s ease-in;
      -ms-transition: opacity 0.4s ease-in;
      -o-transition: opacity 0.4s ease-in;
      transition: opacity 0.4s ease-in; }
    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
      -webkit-animation: pulse 6s ease infinite;
      -moz-animation: pulse 6s ease infinite;
      -ms-animation: pulse 6s ease infinite;
      -o-animation: pulse 6s ease infinite;
      animation: pulse 6s ease infinite; }
    .dropzone .dz-preview .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden; }
      .dropzone .dz-preview .dz-progress .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        -webkit-transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -ms-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out; }
    .dropzone .dz-preview.dz-error .dz-error-message {
      display: block; }
    .dropzone .dz-preview.dz-error:hover .dz-error-message {
      opacity: 1;
      pointer-events: auto; }
    .dropzone .dz-preview .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      border-radius: 8px;
      font-size: 13px;
      top: 130px;
      left: -10px;
      width: 140px;
      background: #be2626;
      background: linear-gradient(to bottom, #be2626, #a92222);
      padding: 0.5em 1.2em;
      color: white; }
      .dropzone .dz-preview .dz-error-message::after {
        content: '';
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #be2626; }

.login {
  position: relative;
  background-color: #FFF;
  padding: 0; }
  @media (max-width: 1200px) {
    .login {
      background: linear-gradient(to bottom, #4a9bec, #1c82e7);
      background-repeat: no-repeat;
      background-attachment: fixed; } }
  .login:before {
    content: "";
    margin-left: -48%;
    background-image: url(../images/bg-login-page.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 1200px) {
      .login:before {
        display: none; } }
  .login__input {
    min-width: 350px;
    box-shadow: 0px 3px 5px #00000007; }
    @media (max-width: 1200px) {
      .login__input {
        min-width: 100%; } }
